import {
  Divider,
  ListItemIcon,
  ListSubheader,
  MenuItem,
} from "@material-ui/core";
import axios from "axios";
import React from "react";
import { useTranslation } from "react-i18next";
import { CodeDescription } from "serverData";

type Props = {
  items: CodeDescription[];
  selectedItem: CodeDescription;
  translationKey: string;
  icon: React.ReactNode;
  urlBuilder: (code: string) => string;
  divider?: boolean;
};

export const MobileMenuSelector: React.FC<Props> = ({
  items,
  selectedItem,
  translationKey,
  icon,
  urlBuilder,
  divider,
}) => {
  const { t } = useTranslation();
  if (items.length < 2) return null;
  return (
    <>
      <ListSubheader>{t(translationKey)}</ListSubheader>

      {items.map((item) => (
        <MenuItem
          onClick={() => {
            axios
              .post(urlBuilder(item.code))
              .then(() => window.location.reload());
          }}
        >
          {item.code !== selectedItem.code ? (
            <ListItemIcon />
          ) : (
            <ListItemIcon>{icon}</ListItemIcon>
          )}
          {item.description}
        </MenuItem>
      ))}

      {divider && <Divider />}
    </>
  );
};
