import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Hidden,
  Theme,
  IconButton,
  makeStyles,
  Typography,
  Button,
  Grid,
  ButtonGroup,
  Box,
  useTheme,
  Chip,
  useMediaQuery,
  Fade,
  TextField,
  InputAdornment,
} from "@material-ui/core";

import { State } from "reducer";
import { areRingsEqual, Email } from "models";
import { Page } from "components/Page";

import {
  updateConfigurator,
  ConfiguratorUpdateType,
  sendEmail,
} from "configurator/model/actions";
import { ConfiguratorValue, Value } from "configurator/model/model";

import { removeItem, addItem } from "wishlist/model/actions";

import { ringUrl, parseRingFromUrl } from "../view/urls";
import { ConfigurationType, RingView } from "configurator/model/model";
import { serverData } from "serverData";
import { createOrder } from "utils/createOrder";
import { EmailDialog } from "components/EmailDialog";
import { Fullbleed } from "components/Fullbleed";

import Edit from "icons/Edit";
import ExpandMore from "icons/ExpandMore";
import FavoritePlus from "icons/FavoritePlus";
import FavoriteSelected from "icons/FavoriteSelected";
import TouchApp from "icons/TouchApp";

import { ProductDetails } from "./ProductDetails";
import { ProductInformation } from "./ProductInformation";
import { AlternativeDialog } from "./AlternativeDialog";
import { ConfigurationDialog } from "./ConfigurationDialog";
import { Thumbnail } from "./Thumbnail";
import { CustomButtons } from "components/CustomButtons";

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      margin: "0 auto",
      maxWidth: "1190px",
      marginRight: "-16px",
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: 0,
    },
    // eslint-disable-next-line no-useless-computed-key
    ["@media print"]: {
      display: "block",
    },
  },
  leftColumn: {
    background: "white",
    marginBottom: "16px",
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
    [theme.breakpoints.up("md")]: {
      width: "34%",
    },
    // eslint-disable-next-line no-useless-computed-key
    ["@media print"]: {
      width: "100%",
    },
  },
  centerColumn: {
    display: "none",

    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(2),
      display: "block",
      width: "33%",
      background: "white",
    },
    // eslint-disable-next-line no-useless-computed-key
    ["@media print"]: {
      display: "block",
      width: "100%",
    },
  },
  rightColumn: {
    background: theme.palette.primary.light,
    marginTop: 0,
    [theme.breakpoints.up("sm")]: {
      width: "50%",
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      width: "33%",
    },
    // eslint-disable-next-line no-useless-computed-key
    ["@media print"]: {
      display: "none",
    },
  },
}));

export const ConfiguratorPage = () => {
  const [
    selectedConfigurationType,
    setSelectedConfigurationType,
  ] = React.useState<ConfigurationType | null>(null);
  const [isEmailModalOpen, setIsEmailModalOpen] = React.useState<boolean>(
    false
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles({ maxWidth: "sm" });
  const { t } = useTranslation();
  const isMobile = useMediaQuery(useTheme().breakpoints.down("xs"));

  useEffect(() => {
    dispatch(
      updateConfigurator({
        type: ConfiguratorUpdateType.Initial,
        ...parseRingFromUrl(history.location.search),
      })
    );
  }, [history, dispatch]);

  const { isLoading, isUpdating, ringView, wishListItemId } = useSelector(
    selector
  );

  useEffect(() => {
    if (ringView) {
      history.replace(ringUrl("ring", ringView));
    }
  }, [ringView, history]);

  const onTypeClick = (configurationType: ConfigurationType) => {
    setSelectedConfigurationType(configurationType);
    dispatch(
      updateConfigurator({
        type: ConfiguratorUpdateType.ConfigurationType,
        configurationTypeKey: configurationType.key,
      })
    );
  };

  const onConfiguratorValueClick = (
    value: ConfiguratorValue<Value>,
    selectedConfigurationType: ConfigurationType
  ) => {
    switch (selectedConfigurationType.key) {
      case "Purity":
        //@ts-ignore,
        dispatch(
          //@ts-ignore,
          updateConfigurator({
            type: ConfiguratorUpdateType.Purity,
            purityInCarat: value.value as number,
          })
        );
        setSelectedConfigurationType(null);

        break;
      case "RingSize":
        //@ts-ignore,
        dispatch(
          //@ts-ignore,
          updateConfigurator({
            type: ConfiguratorUpdateType.RingSize,
            ringSize: value.value as number,
          })
        );

        setSelectedConfigurationType(null);
        break;
      default:
        dispatch(
          updateConfigurator({
            type: ConfiguratorUpdateType.Reference,
            reference: value.ring!.reference,
          })
        );

        setSelectedConfigurationType(null);
    }
  };

  const onBack = () => {
    history.push("/search");
  };

  const onSubmit = (email: Email) => dispatch(sendEmail(email));

  return (
    <Page isLoading={isLoading || isUpdating} onBack={onBack}>
      {ringView && (
        <>
          <div className={classes.body}>
            <div className={classes.leftColumn}>
              <ImageSelector
                ringView={ringView}
                onAddToWishList={() =>
                  dispatch(
                    addItem({
                      reference: ringView.reference,
                      purity: ringView.purity,
                    })
                  )
                }
                onRemoveFromWishList={() => {
                  wishListItemId && dispatch(removeItem(wishListItemId));
                }}
                isAddedToWishlist={!!wishListItemId}
              />

              <Typography variant="caption" component="span">
                ⁽ⁱ⁾ {t("Ring_PriceValidUntil")} {ringView.price.currentDate}
              </Typography>

              <Hidden xsDown>
                <ProductInformation ringView={ringView} variant="body2" />
              </Hidden>
            </div>

            <div className={classes.centerColumn}>
              <ProductDetails ringView={ringView} />
            </div>

            <Fullbleed className={classes.rightColumn} isEnabled={isMobile}>
              <div style={{ padding: "16px" }}>
                <Sidebar
                  ringView={ringView}
                  onTypeClick={onTypeClick}
                  onEmailClick={() => setIsEmailModalOpen(true)}
                  onBack={onBack}
                />
              </div>

              <Hidden mdUp>
                <Box p={2} style={isMobile ? { backgroundColor: "white" } : {}}>
                  <ProductDetails ringView={ringView} />

                  <Hidden smUp>
                    <div style={{ paddingBottom: "16px" }}>
                      <ProductInformation
                        ringView={ringView}
                        variant="body1"
                        preferShort
                      />
                    </div>
                  </Hidden>
                </Box>
              </Hidden>
            </Fullbleed>
          </div>

          <ConfigurationTypeDialog
            selectedConfigurationType={selectedConfigurationType}
            setSelectedConfigurationType={setSelectedConfigurationType}
            ringView={ringView}
            onClick={onConfiguratorValueClick}
          />

          <EmailDialog
            open={isEmailModalOpen}
            onClose={() => setIsEmailModalOpen(false)}
            //@ts-ignore
            onSubmit={onSubmit}
            explanation={t("EmailDialog_Text")}
            title={t("EmailDialog_Title")}
          />
        </>
      )}
    </Page>
  );
};

function selector(rootState: State) {
  const wishListItemId = rootState.configurator.ringView
    ? rootState.wishList.items.find((item) =>
        areRingsEqual(item, rootState.configurator.ringView!)
      )?.id ?? null
    : null;

  return {
    ...rootState.configurator,
    wishListItemId,
  };
}

const useStyles1 = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.primary.light,
  },
}));

const Navigation = ({
  ringView,
  children,
  onEmailClick,
  onBack,
}: {
  ringView: RingView;
  children?: any;
  onEmailClick: () => void;
  onBack: () => void;
}) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down("xs"));
  const { t } = useTranslation();
  const classes = useStyles1();

  const onOrder = () => {
    if (ringView) {
      createOrder(() => {
        // TODO: Handle error
      }, ringView);
    }
  };

  return (
    <div className={classes.root}>
      <CustomButtons
        buttons={serverData.buttons.filter(
          (b) => b.productMenuSort !== null && b.productMenuSort < 0
        )}
        sortingKey="productMenuSort"
        ringView={ringView}
        extraProps={{ fullWidth: true, color: "primary", variant: "contained" }}
        boxed
      />

      {serverData.canOrder && (
        <Box mt={1}>
          <Button
            id="order-btn"
            variant="contained"
            color="primary"
            onClick={onOrder}
            fullWidth
          >
            {t("Order")}
          </Button>
        </Box>
      )}
      {serverData.placeExternalOrderUrl && (
        <Box mt={1}>
          <Button
            id="place-external-order"
            variant="contained"
            color="primary"
            href={
              serverData.placeExternalOrderUrl + ringUrl("", ringView, false)
            }
            fullWidth
          >
            {t("PlaceExternalOrder")}
          </Button>
        </Box>
      )}
      {serverData.makeAppointmentUrl && (
        <Box mt={1}>
          <Button
            variant="contained"
            color="primary"
            href={serverData.makeAppointmentUrl}
            target="_blank"
            rel="noopener noreferrer"
            fullWidth
          >
            {t("MakeAppointment")}
          </Button>
        </Box>
      )}

      <Box mt={1}>
        <Button variant="outlined" color="primary" onClick={onBack} fullWidth>
          {t("Ring_BackToSearch")}
        </Button>
      </Box>

      {((!isMobile && !serverData.hidePrintButton) ||
        !serverData.hideSendEmailButton) && (
        <Box mt={1}>
          <ButtonGroup color="primary" fullWidth>
            {!isMobile && !serverData.hidePrintButton && (
              <Button onClick={() => window.print()}>{t("Ring_Print")}</Button>
            )}
            {!serverData.hideSendEmailButton && (
              <Button onClick={onEmailClick}>{t("Ring_EmailButton")}</Button>
            )}
          </ButtonGroup>
        </Box>
      )}

      <CustomButtons
        buttons={serverData.buttons.filter(
          (b) => b.productMenuSort !== null && b.productMenuSort > 0
        )}
        sortingKey="productMenuSort"
        ringView={ringView}
        extraProps={{ fullWidth: true, color: "primary", variant: "contained" }}
        boxed
      />
      {children}
    </div>
  );
};

const useStyles2 = makeStyles(() => ({
  select: {
    cursor: "pointer",
  },
}));

const Sidebar = ({
  ringView,
  onTypeClick,
  onEmailClick,
  onBack,
}: {
  ringView: RingView;
  onTypeClick: any;
  onEmailClick: () => void;
  onBack: () => void;
}) => {
  const lineRef = React.useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const classes = useStyles2();

  const [isIntersecting, setIsIntersecting] = React.useState(false);

  React.useEffect(() => {
    let isMounted = true;

    const observer = new IntersectionObserver(
      (entries) => {
        if (isMounted) {
          setIsIntersecting(entries[0].isIntersecting);
        }
      },
      { threshold: [0, 1] }
    );

    if (lineRef.current) {
      observer.observe(lineRef.current);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Box displayPrint="none">
      <Hidden xsDown>
        <Navigation
          ringView={ringView}
          onEmailClick={onEmailClick}
          onBack={onBack}
        >
          <div style={{ marginTop: "8px", marginBottom: "16px" }}>
            <Fade in={!isIntersecting}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <TouchApp />

                <Typography variant="body2" style={{ paddingLeft: "8px" }}>
                  {t("Ring_ScrollToView")}
                </Typography>
              </div>
            </Fade>
          </div>
        </Navigation>
      </Hidden>

      <div style={{ marginBottom: "16px" }}>
        {ringView.configurationTypes.map((configurationType, idx) => (
          <div key={configurationType.key} style={{ marginBottom: "0px" }}>
            <div style={{ paddingTop: idx !== 0 ? "16px" : 0 }}>
              <Typography variant="h4">
                {configurationType.description}
              </Typography>
            </div>

            {ringView.configurationTypes.length === idx + 1 && (
              <div style={{ height: "1px" }} ref={lineRef}></div>
            )}

            <TextField
              variant="outlined"
              margin="dense"
              value={configurationType.value}
              onClick={() => onTypeClick(configurationType)}
              inputProps={{ className: classes.select }}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <ExpandMore />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </div>
        ))}
      </div>

      <Hidden smUp>
        <Navigation
          ringView={ringView}
          onEmailClick={onEmailClick}
          onBack={onBack}
        />
      </Hidden>
    </Box>
  );
};

const useStyles3 = makeStyles((theme: Theme) => ({
  thumbnail: {
    marginBottom: 0,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    cursor: "pointer",
  },
  thumbnailImage: {
    maxWidth: "100%",
    borderRadius: theme.shape.borderRadius - 1,
  },
}));

const ImageSelector: React.FC<{
  ringView: RingView;
  isAddedToWishlist: boolean;
  onAddToWishList: () => void;
  onRemoveFromWishList: () => void;
}> = ({
  ringView: {
    imageUrls,
    price,
    ringCollection: { url: ringCollectionUrl },
  },
  isAddedToWishlist,
  onRemoveFromWishList,
  onAddToWishList,
}) => {
  const [currentUrl, setCurrentUrl] = React.useState(imageUrls[0]);
  const ref = React.useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const { t } = useTranslation();
  const classes = useStyles3();

  useEffect(() => {
    setCurrentUrl(imageUrls[0]);
  }, [imageUrls]);

  const onEdit = () => {
    const y =
      ref.current!.getBoundingClientRect().bottom + window.pageYOffset - 60;

    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const onChange = (url: string) => {
    setCurrentUrl(url);
    if (isMobile) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <Grid container spacing={2} style={{ marginTop: "-8px" }}>
      <Grid item xs={12} ref={ref}>
        <Thumbnail
          src={currentUrl}
          inline={
            <Hidden xsDown>
              <img
                src={ringCollectionUrl}
                alt=""
                style={{
                  width: "50%",
                  objectFit: "contain",
                  marginLeft: "16px",
                  marginTop: "16px",
                }}
              />
            </Hidden>
          }
        >
          {!serverData.hideLogin && (
            <Box displayPrint="none">
              {!isAddedToWishlist && (
                <IconButton
                  size="medium"
                  style={{ position: "absolute", bottom: "0px", right: "0px" }}
                  onClick={onAddToWishList}
                >
                  <FavoritePlus style={{ width: "24px", height: "24px" }} />
                </IconButton>
              )}

              {isAddedToWishlist && (
                <IconButton
                  size="medium"
                  style={{ position: "absolute", bottom: "0px", right: "0px" }}
                  onClick={onRemoveFromWishList}
                >
                  <FavoriteSelected
                    style={{ width: "24px", height: "24px" }}
                    htmlColor={theme.palette.primary.main}
                  />
                </IconButton>
              )}
            </Box>
          )}

          <div
            style={{
              position: "absolute",
              bottom: "8px",
              left: "8px",
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <Chip
              label={`${
                (
                  price.additionalCurrencyPriceInfo ||
                  price.standardCurrencyPriceInfo
                ).formattedPrice
              } ⁽ⁱ⁾`}
            />
          </div>
        </Thumbnail>
      </Grid>

      {imageUrls.map((url) => (
        <Grid item key={url} xs={3}>
          <div className={classes.thumbnail} onClick={() => onChange(url)}>
            <img className={classes.thumbnailImage} src={url} alt="" />
          </div>
        </Grid>
      ))}

      <Hidden smUp>
        <Grid item xs={6}>
          <img
            src={ringCollectionUrl}
            alt=""
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </Grid>

        <Grid item xs={6}>
          <Box displayPrint="none">
            <Button
              variant="contained"
              style={{ height: "100%" }}
              onClick={onEdit}
              color="primary"
              fullWidth
            >
              <Edit style={{ width: "40px", height: "40px", color: "white" }} />{" "}
              {t("Ring_Edit")}
            </Button>
          </Box>
        </Grid>
      </Hidden>
    </Grid>
  );
};

type ConfigurationTypeDialogProps = {
  ringView: RingView;
  selectedConfigurationType: ConfigurationType | null;
  setSelectedConfigurationType: (value: ConfigurationType | null) => void;
  onClick: (
    value: ConfiguratorValue<Value>,
    selectedConfigurationType: ConfigurationType
  ) => void;
};

const ConfigurationTypeDialog: React.FC<ConfigurationTypeDialogProps> = ({
  selectedConfigurationType,
  setSelectedConfigurationType,
  ringView,
  onClick: doClick,
}: ConfigurationTypeDialogProps) => {
  const [alternative, setAlternative] = React.useState<ConfiguratorValue<
    Value
  > | null>(null);

  const selectedTypeValues =
    selectedConfigurationType &&
    ringView.selectedTypeValues[selectedConfigurationType.key]
      ? ringView.selectedTypeValues[selectedConfigurationType.key]
      : null;

  const onClick = (value: ConfiguratorValue<Value>) => {
    if (!value.ring || value.ring.newValues.length === 0) {
      doClick(value, selectedConfigurationType!);
    } else {
      setAlternative(value);
    }
  };

  const onAccept = () => {
    if (alternative) {
      setAlternative(null);
      doClick(alternative, selectedConfigurationType!);
    }
  };

  const onReject = () => {
    setAlternative(null);
  };

  return (
    <>
      <AlternativeDialog
        open={!!alternative}
        onClose={() => setAlternative(null)}
        alternative={alternative}
        onAccept={onAccept}
        onReject={onReject}
      />

      <ConfigurationDialog
        open={selectedConfigurationType !== null}
        onClose={() => setSelectedConfigurationType(null)}
        selectedTypeValues={selectedTypeValues}
        onClick={onClick}
        title={selectedConfigurationType?.description || ""}
      />
    </>
  );
};
