import React from "react";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { Button, Container, Grid, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import axios from "axios";

import { Page } from "components/Page";
import PasswordInput from "components/PasswordInput";

type FormValues = {
  oldpassword: string;
  newpassword: string;
};

const initialValues: FormValues = {
  oldpassword: "",
  newpassword: "",
};

const createValidationSchema = (t: TFunction) =>
  Yup.object({
    oldpassword: Yup.string().required(
      t("Account_ChangePassword_OldPassword_required")
    ),
    newpassword: Yup.string()
      .required(t("Account_ChangePassword_NewPassword_required"))
      .min(8, t("Account_ChangePassword_NewPassword_MinimumLength")),
  });

export const ChangePasswordPage = ({ history }: RouteComponentProps) => {
  const { t } = useTranslation();

  const onSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>
  ) => {
    try {
      await axios.post("/api/account/changepassword", values);

      history.push("/account/profile");
    } catch {}
  };

  const validationSchema = createValidationSchema(t);

  const onBack = () => history.push("/account/profile");

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <Page isLoading={isSubmitting} onBack={onBack} sidebar>
          <Container maxWidth="sm">
            <Form>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <Typography variant="h3">
                    {t("Account_ChangePassword")}
                  </Typography>
                </Grid>
                <Grid item>
                  <PasswordInput
                    name="oldpassword"
                    variant="outlined"
                    label={t("Account_ChangePassword_Currentpassword")}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <PasswordInput
                    name="newpassword"
                    variant="outlined"
                    label={t("Account_ChangePassword_Newpassword")}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                  >
                    {t("Account_ChangePassword")}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </Container>
        </Page>
      )}
    </Formik>
  );
};
