import React, { useMemo } from "react";
import {
  makeStyles,
  Theme,
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  Grid,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

import Close from "icons/Close";
import { TextFieldInput } from "components/TextFieldInput";
import { DialogExplanation } from "components/DialogExplanation";
import { Email } from "models";
import { Fullbleed } from "./Fullbleed";

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(2),
    width: "100%",
  },
}));

type FormValues = {
  to: string;
  message: string;
};

const initialValues: FormValues = {
  to: "",
  message: "",
};

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (email: Email) => Promise<any>;
  title: string;
  explanation: string;
};

export const EmailDialog: React.FC<Props> = ({
  open,
  onClose,
  onSubmit: doSubmit,
  title,
  explanation,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const onSubmit = async (values: FormValues) => {
    try {
      await doSubmit({
        toAddresses: [values.to],
        bccAddress: null,
        ccAddresses: [],
        extraMessage: values.message,
      });

      onClose();
    } catch {
      // TODO
    }
  };

  const validationSchema = useMemo(() => createValidationSchema(t), [t]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <Form>
        <Dialog
          open={open}
          onClose={onClose}
          maxWidth="sm"
          fullWidth
          disablePortal
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">E-mail</Typography>
            <IconButton className={classes.closeButton} onClick={onClose}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Fullbleed isEnabled>
              <DialogExplanation title={title}>
                <Typography variant="body1">{explanation}</Typography>
              </DialogExplanation>

              <Box m={2}>
                <Grid container spacing={2} direction="column">
                  <Grid item>
                    <TextFieldInput
                      name="to"
                      label="E-mail"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <TextFieldInput
                      name="message"
                      label={t("Email_Message")}
                      variant="outlined"
                      rows={7}
                      multiline
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Box>
            </Fullbleed>
          </DialogContent>
          <DialogActions>
            <Button color="primary" variant="contained" type="submit" fullWidth>
              {t("Email_Send")}
            </Button>
          </DialogActions>
        </Dialog>
      </Form>
    </Formik>
  );
};

const createValidationSchema = (t: TFunction) =>
  Yup.object({
    to: Yup.string()
      .required(t("EmailDialog_Required"))
      .email(t("EmailDialog_InvalidEmail")),

    message: Yup.string().required(t("EmailDialog_Required")),
  });
