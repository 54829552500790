/* eslint-disable no-undef */

import axios from "axios";
// This is made to interact with the currency dropdown outside of the react application.
// Would be nicer to have the whole site in react...

export function updateMakeAppointmentUrls(query) {
  $('a[data-make-appointment="true"]').each(function () {
    var href = $(this).attr("href");
    if (href) {
      const url = new URL(href);
      url.search = query;
      $(this).attr("href", url.href);
    }
  });
}

$(window).load(function () {
  $(function () {
    $("#currency-dropdown").on("click", "li", function (evt) {
      evt.preventDefault();
      axios
        .post(
          "/update-currency?currency=" + evt.target.getAttribute("data-value")
        )
        .then(() => window.location.reload());
    });

    $("#language-dropdown").on("click", "li", function (evt) {
      evt.preventDefault();
      axios
        .post(
          "/update-language?language=" + evt.target.getAttribute("data-value")
        )
        .then(() => window.location.reload());
    });

    $("#country-dropdown").on("click", "li", function (evt) {
      evt.preventDefault();
      axios
        .post(
          "/update-country?country=" + evt.target.getAttribute("data-value")
        )
        .then(() => window.location.reload());
    });
  });
});
