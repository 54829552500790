import React from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {
  AppBar,
  Box,
  Button,
  Theme,
  makeStyles,
  Toolbar,
  useMediaQuery,
  IconButton,
  Badge,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { State } from "reducer";
import { logoutAndRedirect } from "account/model/actions";
import { serverData } from "serverData";

import Favorite from "icons/Favorite";

import { UserMenu } from "./UserMenu";
import { Dropdown } from "./Dropdown";
import skin from "skin";
import { CustomButtons } from "components/CustomButtons";

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    maxWidth: "70vw",
    maxHeight: "36px",
    cursor: "pointer",
  },
  headerContent: {
    columnGap: theme.spacing(1),
  },
  headerImage: {
    width: "100%",
    maxWidth: "1180px",
    height: `${skin.headerHeight}px`,
    backgroundImage: `url(${skin.headerPath})`,
    backgroundSize: "cover",
    backgroundPositionX: "center",
    marginBottom: "16px",
  },
}));

type Props = {
  numberOfWishlistItems?: number;
};

export const Desktop: React.FC<Props> = ({
  children,
  numberOfWishlistItems = 0,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const isFullscreen = useMediaQuery("(min-width:1206px)");
  const username = useSelector((s: State) => s.account.username);
  const classes = useStyles();

  const onLogout = async () => {
    dispatch(logoutAndRedirect("/#/search"));
  };

  const onSettings = () => {
    history.push("/account/profile");
  };

  const onLogoClick = () => {
    if (serverData.logoUrl) {
      window.location.href = serverData.logoUrl;
    } else {
      history.push("/search");
    }
  };

  return (
    <>
      <AppBar color="default" elevation={3} position="absolute">
        <Toolbar
          style={{
            width: "100%",
            maxWidth: "1180px",
            margin: "0 auto",
            paddingLeft: isFullscreen ? "4px" : "12px",
            paddingRight: isFullscreen ? "4px" : "12px",
          }}
        >
          <img
            src={skin.logoPath}
            alt=""
            className={classes.logo}
            onClick={onLogoClick}
          />
          <Box
            display="flex"
            flex={1}
            justifyContent="flex-end"
            alignItems="center"
            className={classes.headerContent}
            displayPrint="none"
          >
            <CustomButtons
              buttons={serverData.buttons.filter(
                (b) => b.mainMenuSort !== null && b.mainMenuSort < 0
              )}
              sortingKey="mainMenuSort"
            />

            <Dropdown
              items={serverData.allCurrencies}
              selected={serverData.currency}
              onChange={(code) =>
                axios
                  .post("/update-currency?currency=" + code)
                  .then(() => window.location.reload())
              }
            />

            <Dropdown
              items={serverData.allCountries}
              selected={serverData.country}
              onChange={(code) =>
                axios
                  .post("/update-country?country=" + code)
                  .then(() => window.location.reload())
              }
            />

            <Dropdown
              items={serverData.allLanguages}
              selected={serverData.language}
              onChange={(code) =>
                axios
                  .post("/update-language?language=" + code)
                  .then(() => window.location.reload())
              }
            />

            {username && (
              <IconButton onClick={() => history.push("/WishList")}>
                <Badge badgeContent={numberOfWishlistItems} color="primary">
                  <Favorite style={{ width: "24px", height: "24px" }} />
                </Badge>
              </IconButton>
            )}

            {serverData.makeAppointmentUrl && (
              <Button
                variant="contained"
                color="primary"
                href={serverData.makeAppointmentUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("MakeAppointment")}
              </Button>
            )}

            <CustomButtons
              buttons={serverData.buttons.filter(
                (b) => b.mainMenuSort !== null && b.mainMenuSort > 0
              )}
              sortingKey="mainMenuSort"
            />

            {username && (
              <UserMenu onLogout={onLogout} onSettings={onSettings} />
            )}

            {!username && !serverData.hideLogin && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => history.push("/account/login")}
              >
                {t("LoginPartial_LogIn")}
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <div
        style={{
          width: "100%",
          maxWidth: "1180px",
          minHeight: "calc(100% - 64px)",
          margin: "0 auto",
        }}
      >
        <Box displayPrint={"none"}>
          <div className={classes.headerImage} />
        </Box>
        {children}
      </div>

      {serverData.footerHtml && (
        <div dangerouslySetInnerHTML={{ __html: serverData.footerHtml }} />
      )}
    </>
  );
};
