import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import { useTranslation } from "react-i18next";

import { Page } from "components/Page";
import { State } from "reducer";
import { useSelector } from "react-redux";

export const ProfilePage = ({ history }: RouteComponentProps) => {
  const { t } = useTranslation();
  const username = useSelector((s: State) => s.account.username);

  return (
    <Page isLoading={false}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h3">
            {t("Profile_Welcome")} {username},
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => history.push("/account/change-password")}
          >
            {t("Profile_ChangePasswordButtonLabel")}
          </Button>
        </Grid>
      </Grid>
    </Page>
  );
};
