import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { ConfiguratorValue, Value } from "configurator/model/model";

type Props = {
  open: boolean;
  onClose: () => void;
  alternative: ConfiguratorValue<Value> | null;
  onAccept: () => void;
  onReject: () => void;
};

export const AlternativeDialog: React.FC<Props> = ({
  open,
  onClose,
  alternative,
  onAccept,
  onReject,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      {alternative && alternative.ring && (
        <>
          <DialogTitle>{t("Alternative_Title")}</DialogTitle>
          <DialogContent>
            <Box mb={2}>
              <Typography variant="body1">
                {t("Alternative_Description")}
              </Typography>
            </Box>

            <Box mb={2}>
              <Paper variant="outlined">
                <List>
                  {alternative.ring.newValues.map((newValue, idx) => (
                    <ListItem
                      key={newValue.key}
                      divider={alternative.ring!.newValues.length !== idx + 1}
                    >
                      <ListItemText
                        primary={newValue.key}
                        secondary={newValue.value}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Box>

            <Box mb={2}>
              <Button
                color="primary"
                variant="contained"
                onClick={onAccept}
                fullWidth
              >
                {t("Alternative_Continue")}
              </Button>
            </Box>

            <Button
              color="primary"
              variant="outlined"
              onClick={onReject}
              fullWidth
            >
              {t("Alternative_Back")}
            </Button>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};
