import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import createPalette from "@material-ui/core/styles/createPalette";
import createTypography from "@material-ui/core/styles/createTypography";
import { Overrides } from "@material-ui/core/styles/overrides";
import { Skin } from "themes/model";
import { createCustomTheme } from "./createCustomTheme";

const logoPath =
  "https://ringconfigimg.blob.core.windows.net/skins/Siebel/logo_web.png";

const headerPath =
  "https://ringconfigimg.blob.core.windows.net/skins/Siebel/bg.jpg";

export const palette = createPalette({
  common: {
    white: "#ffffff",
    black: "#000000",
  },
  primary: {
    light: "#f7f0e4",
    main: "#c4a36c",
  },
  text: {
    secondary: "#666666",
    primary: "#1F1F1F",
  },
  error: {
    light: "#FF5833",
    main: "#E43A15",
    dark: "#D02601",
  },
  warning: {
    light: "#FF893E",
    main: "#EF6B20",
    dark: "#DB570C",
  },
  info: {
    light: "#1E97FD",
    main: "#0079DF",
    dark: "#0065CB",
  },
  success: {
    light: "#1EC669",
    main: "#00A84B",
    dark: "#009437",
  },
  background: {
    default: "#ffffff",
  },
  grey: {
    100: "#F7F7F7",
    200: "#EEEEEE",
    300: "#E7E7E7",
    400: "#E4E2E3",
    500: "#B2B2B2",
    600: "#8C8C8C",
    700: "#666666",
    800: "#333333",
    900: "#1F1F1F",
  },
  divider: "#E4E2E3",
});

const fontFamily = "ff-dax-pro";

export const typography = createTypography(palette, {
  h1: {
    fontStyle: "normal",
    fontWeight: 900,
    fontFamily,
    fontSize: 33,
    lineHeight: 1,
    letterSpacing: 0,
  },
  h2: {
    fontStyle: "normal",
    fontWeight: 900,
    fontFamily,
    fontSize: "26px",
    lineHeight: 1,
    letterSpacing: 0,
  },
  h3: {
    fontStyle: "normal",
    fontWeight: 400,
    fontFamily,
    fontSize: "20px",
    lineHeight: 1,
    letterSpacing: 0,
  },
  h4: {
    fontFamily,
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: 1,
    fontWeight: 400,
    letterSpacing: 0,
  },
  h5: {
    fontFamily,
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: 1.2,
    fontWeight: 700,
    letterSpacing: 0,
  },
  h6: {
    fontFamily,
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: 900,
    lineHeight: 1,
    letterSpacing: 0,
  },
  subtitle1: {
    fontFamily,
    fontStyle: "normal",
    fontSize: "12px",
    lineHeight: 1.2,
    fontWeight: 400,
    letterSpacing: 0,
  },
  subtitle2: {
    fontFamily,
    fontStyle: "normal",
    fontSize: "22px",
    lineHeight: 1.2,
    fontWeight: 300,
    letterSpacing: 0,
    textTransform: "uppercase" as "uppercase",
  },
  button: {
    fontFamily,
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: 1,
    letterSpacing: "1px",
  },
  caption: {
    fontFamily,
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: 1.4,
    letterSpacing: 0,
  },
  body1: {
    fontFamily,
    fontSize: "16px",
    fontWeight: 300,
    lineHeight: 1.4,
    letterSpacing: 0,
  },
  body2: {
    fontFamily,
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: 1.4,
    letterSpacing: 0,
    "-webkit-font-smoothing": "subpixel-antialiased",
  },
});

const shape = {
  borderRadius: 5,
};

const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1206,
    xl: 1920,
  },
});

const overrides: Overrides = {
  MuiCssBaseline: {
    "@global": {
      "@font-face": [
        {
          fontFamily,
          fontDisplay: "auto",
          fontStyle: "normal",
          fontWeight: 400,
          src: `url("https://use.typekit.net/af/9f3f13/0000000000000000773599be/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/9f3f13/0000000000000000773599be/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/9f3f13/0000000000000000773599be/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype")`,
        },
        {
          fontFamily,
          fontDisplay: "auto",
          fontStyle: "normal",
          fontWeight: 700,
          src: `url("https://use.typekit.net/af/831858/0000000000000000773599ab/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/831858/0000000000000000773599ab/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/831858/0000000000000000773599ab/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype")`,
        },
      ],
      ".value-icon":
      {
        height: ".75em"
      },
    },
  },
};

export const skin: Skin = {
  logoPath,
  headerPath,
  headerHeight: 210,
  theme: createCustomTheme(palette, typography, shape, breakpoints, overrides),
};
