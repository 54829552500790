import { FilterUpdate } from "./actions";

export type Optional<T> = {
  [P in keyof T]: T[P] | null;
};

export interface FilterItem {
  id: number;
  description: string;
  isSelected: boolean;
  filterUpdate: FilterUpdate;
}

export enum FilterType {
  Stones,
  Materials,
  Collections,
  Tags,
}

export interface FilterCategory {
  filterType: FilterType;
  items: FilterItem[];
}

export type PriceRange = {
  min: number;
  max: number;
};

export interface FilterInfo {
  priceRange: PriceRange;
  priceLevels: PriceLevel[];
  currencySymbol: string;
  categories: FilterCategory[];
  selection: FilterSelection;
}

interface PriceLevel {
  amount: number;
  amountFormatted: string;
}

export interface Sorting {
  sortType: SortType;
  sortOrder: SortOrder;
}

export enum SortType {
  Default,
  Price,
  Popularity,
  NewlyAdded,
}

export enum SortOrder {
  Ascending,
  Descending,
}

export interface FilterSelection {
  priceRange: Optional<PriceRange>;
  categories: FilterCategorySelection[];
  sorting: Sorting;
}

export interface UrlFilterSelection {
  priceRange: Optional<PriceRange>;
  categories: FilterCategorySelection[];
  sorting: Optional<Sorting>;
}

export interface FilterCategorySelection {
  filterType: FilterType;
  ids: number[];
}

export interface SearchResults {
  lastPageNumber: number;
  totalCount: number;
  totalLoaded: number;
  pages: Page[];
}

export interface Page {
  pageNumber: number;
  items: PageItem[];
}

export interface PageItem {
  ringTypeDescription: string;
  reference: string;
  previewImageUrl: string;
  minPriceFormatted: string;
  maxPriceFormatted: string;
  collectionImageUrl: string;
}
