import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export default (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" {...props}>
    <path
      fillRule="evenodd"
      d="M11.998 12.465L16.668 9 18 10.546 11.998 15 6 10.546 7.333 9l4.665 3.465z"
      clipRule="evenodd"
    />
  </SvgIcon>
);
