import configuratorReducer, {
  State as ConfiguratorState,
} from "./configurator/model/reducer";
import searchReducer, { State as SearchState } from "./search/model/reducer";
import wishListReducer, {
  State as WishListState,
} from "./wishlist/model/reducer";
import accountReducer, { State as AccountState } from "./account/model/reducer";
import { combineReducers } from "redux";

export type State = {
  readonly configurator: ConfiguratorState;
  readonly search: SearchState;
  readonly wishList: WishListState;
  readonly account: AccountState;
};

export default combineReducers<State>({
  configurator: configuratorReducer,
  search: searchReducer,
  wishList: wishListReducer,
  account: accountReducer,
});
