import React, { useCallback, useRef, useState } from "react";
import {
  InputAdornment,
  Theme,
  Hidden,
  DialogTitle,
  DialogContent,
  Paper,
  Dialog,
  IconButton,
  InputBase,
  makeStyles,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
  List,
  ListItem,
  ListItemSecondaryAction,
  CircularProgress,
} from "@material-ui/core";
import { useAutocomplete } from "@material-ui/lab";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

import ChevronLeft from "icons/ChevronLeft";
import ChevronRight from "icons/ChevronRight";
import Close from "icons/Close";
import { useAsyncCall } from "useAsyncCall";

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    height: "450px",
    paddingBottom: "16px",
  },
  input: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(2),
    },
  },
  menu: {
    '& div[data-focus="true"]': {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
}));

type Props = {
  onClose: () => void;
  open: boolean;
};

export const ReferenceSearchDialog: React.FC<Props> = ({ onClose, open }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isMobile = useMediaQuery(useTheme().breakpoints.down("xs"));
  const [inputValue, setInputValue] = useState<string>("");
  const history = useHistory();
  const inputRef = useRef<HTMLInputElement>();

  const term = inputValue.trim();

  const [options, meta] = useAsyncCall(
    useCallback(() => {
      if (term.length > 0) {
        return axios
          .get<string[]>(`/api/search/references?term=${term}`)
          .then((response) => response.data);
      } else {
        return Promise.resolve([]);
      }
    }, [term])
  );

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: "reference-search",
    options: options || [],
    inputValue,
    open: inputValue !== "",
    autoHighlight: true,
    onChange: (_, option) =>
      history.push({
        pathname: "/ring",
        search: "?reference=" + option,
      }),
  });

  //@ts-ignore
  const { value, onBlur, onChange: doChange, ...inputProps } = getInputProps();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value.toUpperCase());
  };

  const onScroll = () => {
    inputRef.current?.blur();
  };

  return (
    <Dialog
      open={open}
      fullScreen={isMobile}
      onClose={onClose}
      onScroll={onScroll}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle {...getRootProps()} disableTypography>
        <Hidden smUp>
          <IconButton onClick={onClose}>
            <ChevronLeft />
          </IconButton>
        </Hidden>

        <InputBase
          {...inputProps}
          onChange={onChange}
          value={value}
          className={classes.input}
          autoFocus
          fullWidth
          autoComplete="off"
          autoCorrect="off"
          placeholder={t("ReferenceSearch_SearchFieldPlaceholder")}
          inputRef={inputRef}
          endAdornment={
            meta.isLoading && (
              <InputAdornment position="end">
                <CircularProgress size={20} />
              </InputAdornment>
            )
          }
        />

        <Hidden xsDown>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Hidden>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {groupedOptions.length === 0 && term.length > 0 && !meta.isLoading && (
          <Typography variant="body1">
            {t("ReferenceSearch_NoResults")}
          </Typography>
        )}

        {groupedOptions.length > 0 && (
          <Paper>
            <List {...getListboxProps()} className={classes.menu}>
              {groupedOptions.map((option, index) => {
                const matches = match(option, value);
                const parts = parse(option, matches);

                const text = parts.map((part) => {
                  if (part.highlight) {
                    return <strong key={part.text}>{part.text}</strong>;
                  } else {
                    return (
                      <React.Fragment key={part.text}>
                        {part.text}
                      </React.Fragment>
                    );
                  }
                });

                //@ts-ignore
                const { onClick, ...otherProps } = getOptionProps({
                  option,
                  index,
                });

                return (
                  <ListItem
                    key={option}
                    onClick={onClick}
                    divider={index !== groupedOptions.length - 1}
                    {...otherProps}
                  >
                    <ListItemText
                      primary={<Typography variant="body1">{text}</Typography>}
                      disableTypography
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" onClick={onClick}>
                        <ChevronRight />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </Paper>
        )}
      </DialogContent>
    </Dialog>
  );
};
