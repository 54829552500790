import React from "react";
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  PopoverOrigin,
  IconButton,
} from "@material-ui/core";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";
import { useTranslation } from "react-i18next";

import Settings from "icons/Settings";
import Logout from "icons/Logout";
import User from "icons/User";

const anchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "right",
};

const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};

type Props = {
  onLogout: () => void;
  onSettings: () => void;
};

export const UserMenu: React.FC<Props> = ({ onLogout, onSettings }) => {
  const { t } = useTranslation();

  const popupState = usePopupState({
    variant: "popover",
    popupId: "user-menu",
  });

  // The getContentAnchorEl={null} is needed to make sure that the `Menu` appears below the `Button`!
  return (
    <>
      <IconButton {...bindTrigger(popupState)}>
        <User />
      </IconButton>
      <Menu
        {...bindMenu(popupState)}
        getContentAnchorEl={null}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <MenuItem onClick={onSettings} divider>
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText secondary={t("Account_Settings")} />
        </MenuItem>
        <MenuItem onClick={onLogout}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText secondary={t("LoginPartial_LogOff")} />
        </MenuItem>
      </Menu>
    </>
  );
};
