import { Button } from "components/CustomButtons";

const w = window as any;

const allCurrencies = [
  { code: "EUR", description: "Euro" },
  { code: "PLN", description: "Zloty" },
];

const allCountries = [
  { code: "NL", description: "Nederland" },
  { code: "EU", description: "Europa" },
];

const allLanguages = [
  { code: "nl", description: "Nederlands" },
  { code: "fr", description: "Français" },
  { code: "en", description: "English" },
  { code: "de", description: "Deutsch" },
];

type ServerData = {
  skinKey: string;
  logoUrl: string | null;
  currency: CodeDescription;
  allCurrencies: CodeDescription[];
  country: CodeDescription;
  allCountries: CodeDescription[];
  language: CodeDescription;
  allLanguages: CodeDescription[];
  username: string | null;
  bccAddress: string | null;
  hideLogin: boolean;
  hideBccAddress: boolean;
  hidePrintButton: boolean;
  hideSendEmailButton: boolean;
  canOrder: boolean;
  maxOrderRings: number;
  makeAppointmentUrl: string | null;
  placeExternalOrderUrl: string | null;
  footerHtml: string | null;
  buttons: Button[];
};

export type CodeDescription = { code: string; description: string };

const buttons: Button[] = [
  {
    translationKey: "t",
    buttonHtmlId: "left-top1id",
    mainMenuSort: -2,
    productMenuSort: -2,
    productMenuAddUrlDetails: true,
    url: "http://left-top1",
    openInNewWindow: true,
    svgContent: `<path fill="currentColor" d="M22 5v2h-3v3h-2V7h-3V5h3V2h2v3h3zm-3 14H5V5h6V3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-6h-2v6zm-4-6v4h2v-4h-2zm-4 4h2V9h-2v8zm-2 0v-6H7v6h2z"></path>`,
  },
  {
    translationKey: "left-top2",
    buttonHtmlId: "left-top2id",
    mainMenuSort: null,
    productMenuSort: -1,
    productMenuAddUrlDetails: false,
    url: "http://left-top2",
    openInNewWindow: false,
    svgContent: null,
  },
  {
    translationKey: "right-under",
    buttonHtmlId: "right-under",
    mainMenuSort: 1,
    productMenuSort: 1,
    productMenuAddUrlDetails: false,
    url: "http://right-under",
    openInNewWindow: false,
    svgContent: null,
  },
];

const DEFAULT: ServerData = {
  skinKey: "vdbvr",
  logoUrl: "http://vdbvr.be",
  currency: allCurrencies[0],
  allCurrencies,
  country: allCountries[0],
  allCountries,
  language: allLanguages[0],
  allLanguages,
  username: null,
  bccAddress: "",
  hideLogin: true,
  hideBccAddress: false,
  hidePrintButton: false,
  hideSendEmailButton: false,
  canOrder: false,
  maxOrderRings: 0,
  makeAppointmentUrl: "http://localhost",
  placeExternalOrderUrl: "http://external",
  footerHtml: `<footer>
 <div class="container">
  <p>
   <a href="https://www.vdbvr.com/nl/home" target="_blank">&copy; VdB&amp;VR &emsp; www.vdbvr.com &emsp; <i>Lees meer over deze ringen...</i></a>
  </p>
  <p>
   <a href="https://www.vdbvr.com/nl/juweliers" target="_blank">Waar kan je deze trouwringen kopen?</a>
   &emsp;&emsp;
   <a href="https://www.vdbvr.com/nl/privacy" target="_blank">Privacy</a>
   &emsp;&emsp;
   <a href="https://www.vdbvr.com/nl/disclaimer" target="_blank">Disclaimer</a>
  </p>
 </div>
</footer>`,
  buttons: buttons,
};

const getData = () => {
  if (!w.SERVER_DATA) {
    return DEFAULT;
  }

  return w.SERVER_DATA;
};

const serverData: ServerData = getData();

export { serverData };
