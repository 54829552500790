import React from "react";
import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { FilterUpdateType, updateFilter } from "search/model/actions";
import { FilterInfo, FilterType, PriceRange } from "search/model/model";

import ExpandMore from "icons/ExpandMore";
import CheckboxChecked from "icons/CheckboxChecked";
import CheckboxUnchecked from "icons/CheckboxUnchecked";
import CheckboxCheckedFilled from "icons/CheckboxCheckedFilled";

export const getFilterTranslation = (filterType: FilterType) => {
  switch (filterType) {
    case FilterType.Stones:
      return "Search_Stones";
    case FilterType.Materials:
      return "Search_Materials";
    case FilterType.Collections:
      return "Search_Collections";
    case FilterType.Tags:
      return "Search_Tags";
  }
};

type Props = {
  filter: FilterInfo | null;
};

export const Filter: React.FC<Props> = ({ filter }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedItems = filter?.categories
    .flatMap((category) => category.items)
    .filter((item) => item.isSelected);

  // TODO: set this in filter.PriceRange in backend, as soon as this is used everywhere
  const absoluteMin = Math.min.apply(
    Math,
    filter?.priceLevels.map((l) => l.amount) || []
  );
  const absoluteMax = Math.max.apply(
    Math,
    filter?.priceLevels.map((l) => l.amount) || []
  );

  const selectedMin = filter?.selection.priceRange.min || absoluteMin;
  const selectedMax = filter?.selection.priceRange.max || absoluteMax;

  const updatePrice = (priceRange: PriceRange) =>
    dispatch(
      updateFilter(
        {
          type: FilterUpdateType.Price,
          priceRange,
        },
        true
      )
    );

  return (
    <>
      {selectedItems && selectedItems.length > 0 && (
        <>
          <Box m={2} display="flex" justifyContent="space-between">
            <Typography variant="h4">{t("Search_SelectedFilters")}</Typography>
          </Box>

          <Box m={2}>
            {selectedItems.map((item) => (
              <Chip
                key={item.description}
                label={item.description}
                onDelete={() => dispatch(updateFilter(item.filterUpdate, true))}
                color="primary"
                size="small"
                style={{ marginRight: "4px", marginTop: "4px" }}
              />
            ))}
          </Box>

          <Divider />
        </>
      )}

      <Box m={2}>
        <FormControl variant="outlined" fullWidth>
          <FormLabel>{t("Search_MinimumPrice")}</FormLabel>
          <Select
            value={selectedMin}
            onChange={(evt) =>
              updatePrice({ min: evt.target.value as number, max: selectedMax })
            }
            IconComponent={ExpandMore}
          >
            {filter?.priceLevels.map((l) => (
              <MenuItem
                key={l.amount}
                value={l.amount}
                disabled={l.amount >= selectedMax}
              >
                {l.amountFormatted}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box m={2}>
        <FormControl variant="outlined" fullWidth>
          <FormLabel>{t("Search_MaximumPrice")}</FormLabel>
          <Select
            value={selectedMax}
            onChange={(evt) =>
              updatePrice({ min: selectedMin, max: evt.target.value as number })
            }
            IconComponent={ExpandMore}
          >
            {filter?.priceLevels.map((l) => (
              <MenuItem
                key={l.amount}
                value={l.amount}
                disabled={l.amount <= selectedMin}
              >
                {l.amountFormatted}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Divider />

      {filter &&
        filter.categories.map((category) => (
          <React.Fragment key={category.filterType}>
            <Box m={2}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  {t(getFilterTranslation(category.filterType))}
                </FormLabel>
                <FormGroup>
                  {category.items.map((item) => (
                    <FormControlLabel
                      key={item.id}
                      label={item.description}
                      control={
                        <Checkbox
                          indeterminate={category.items.every(
                            (item) => !item.isSelected
                          )}
                          checked={
                            item.isSelected ||
                            category.items.every((item) => !item.isSelected)
                          }
                          style={{ transform: "scale(0.75)" }}
                          onChange={() =>
                            dispatch(updateFilter(item.filterUpdate, true))
                          }
                          icon={<CheckboxUnchecked />}
                          checkedIcon={<CheckboxCheckedFilled />}
                          indeterminateIcon={<CheckboxChecked />}
                        />
                      }
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Box>
            <Divider />
          </React.Fragment>
        ))}
    </>
  );
};
