import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export default (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" {...props}>
    <path
      fillRule="evenodd"
      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm12-10c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M10.823 17.38V9.617h2.354v7.761h-2.354zm0-9.088V6.207h2.354v2.085h-2.354z"
      clipRule="evenodd"
    />
  </SvgIcon>
);
