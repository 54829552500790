import { Action, ACTION } from "./actions";
import { serverData } from "serverData";

export type State = { username: string | null };

const defaultState: State = { username: serverData.username };

export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case ACTION.LOGIN:
      return { username: action.username };
    case ACTION.LOGOUT:
      return { username: null };
    default:
      return state;
  }
};
