import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export default (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.106 4.553A1 1 0 014 4h16a1 1 0 01.8 1.6L15 13.333V21a1 1 0 01-1.447.894l-4-2A1 1 0 019 19v-5.667L3.2 5.6a1 1 0 01-.094-1.047zM6 6l4.8 6.4a1 1 0 01.2.6v5.382l2 1V13a1 1 0 01.2-.6L18 6H6z"
    />
  </SvgIcon>
);
