import React from "react";
import {
  Menu,
  MenuItem,
  ListItemText,
  PopoverOrigin,
  Button,
} from "@material-ui/core";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { CodeDescription } from "serverData";
import ExpandMore from "icons/ExpandMore";

const anchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "right",
};

const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};

type Props = {
  items: CodeDescription[];
  selected: CodeDescription;
  onChange: (code: string) => void;
};

export const Dropdown: React.FC<Props> = ({ items, selected, onChange }) => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: "user-menu",
  });

  if (items.length < 2) return null;

  // The getContentAnchorEl={null} is needed to make sure that the `Menu` appears below the `Button`!
  return (
    <>
      <Button
        variant="text"
        color="default"
        endIcon={<ExpandMore />}
        {...bindTrigger(popupState)}
      >
        {selected.description}
      </Button>
      <Menu
        {...bindMenu(popupState)}
        getContentAnchorEl={null}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        MenuListProps={{ disablePadding: true }}
      >
        {items.map((item, index) => (
          <MenuItem
            key={item.code}
            onClick={() => onChange(item.code)}
            divider={items.length !== index - 1}
          >
            <ListItemText secondary={item.description} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
