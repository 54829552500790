import React from "react";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import { RouteComponentProps } from "react-router";

import { Page } from "components/Page";
import { TextFieldInput } from "components/TextFieldInput";
import PasswordInput from "components/PasswordInput";

type FormValues = {
  emailaddress: string;
  password: string;
};

const initialValues: FormValues = {
  emailaddress: "",
  password: "",
};

const createValidationSchema = (t: TFunction) =>
  Yup.object({
    emailaddress: Yup.string()
      .required(t("Account_Register_EmailId_required"))
      .email(t("Account_Register_InvalidEmailId")),
    password: Yup.string()
      .required(t("Account_Register_Password_required"))
      .min(8, t("Account_Register_Tooshort")),
  });

export const RegisterPage = ({ history }: RouteComponentProps) => {
  const { t } = useTranslation();

  const onSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>
  ) => {
    try {
      await axios.post("/api/account/register", values);

      history.push("/account/login");
    } catch {
      // TODO
    }
  };

  const validationSchema = createValidationSchema(t);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <Page isLoading={isSubmitting} sidebar={true}>
          <Container maxWidth="sm">
            <Form>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <Typography variant="h3">{t("Account_Register")}</Typography>
                </Grid>
                <Grid item>
                  <TextFieldInput
                    name="emailaddress"
                    variant="outlined"
                    type="email"
                    label={t("Account_Register_EmailId")}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <PasswordInput
                    name="password"
                    variant="outlined"
                    label={t("Account_Register_Password")}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                  >
                    {t("Account_Register")}
                  </Button>
                </Grid>
              </Grid>

              <Box mt={4}>
                <Typography variant="body1">
                  {t("Account_Register_AlreadyRegistered")}{" "}
                  <Link onClick={() => history.push("/account/login")}>
                    {t("Account_Register_GoToLogin")}
                  </Link>
                </Typography>
              </Box>
            </Form>
          </Container>
        </Page>
      )}
    </Formik>
  );
};
