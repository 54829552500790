import React, { useEffect, useState } from "react";
import {
  Box,
  useMediaQuery,
  Theme,
  Grid,
  Button,
  Hidden,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  Typography,
  FormControl,
  FormLabel,
  TextField,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";

import { Page } from "components/Page";
import { State } from "reducer";
import {
  initialLoad,
  updateFilter,
  FilterUpdateType,
  loadPage,
} from "search/model/actions";
import { parseSearchQuery, searchUrl } from "search/view/url";
import { Thumbnail } from "configurator/view/Thumbnail";
import FilterIcon from "icons/Filter";

import { ringLink } from "configurator/view/urls";
import { Fullbleed } from "components/Fullbleed";
import { FilterInfo } from "search/model/model";

import { ReferenceSearchDialog } from "./ReferenceSearchDialog";
import { OrderSelect } from "./OrderSelect";
import { Filter } from "./Filter";

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      width: "100%",
    },
  },
  filter: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
      minWidth: "225px",
      maxWidth: "225px",
      marginRight: theme.spacing(2),
      padding: 0,
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "300px",
      maxWidth: "300px",
    },
  },
  thumbnailContainer: {
    cursor: "pointer",
    height: "100%",
  },
}));

export const SearchPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  const search = useSelector((rootState: State) => rootState.search);
  const filter = useSelector(getFilter);
  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();
  const isFullscreen = useMediaQuery("(min-width:1206px)");
  const [open1, setOpen1] = useState<boolean>(false);

  useEffect(() => {
    dispatch(initialLoad(parseSearchQuery(history.location.search)));
  }, [history, dispatch]);

  useEffect(() => {
    if (search.filter) {
      history.replace(searchUrl(search.filter));
    }
  }, [search.filter, history]);

  const isLoading = search.isUpdatingFilter || search.isLoadingInitially;

  return (
    <Page isLoading={isLoading} onReferenceSearch={() => setOpen1(true)}>
      <div className={classes.body}>
        <Fullbleed className={classes.filter} isEnabled={!isFullscreen}>
          <Hidden xsDown>
            <Filter filter={filter} />
          </Hidden>
          <Hidden smUp>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography variant="h3">
                  {t("Search_NumberOfResults", {
                    totalCount: search.results.totalCount,
                  })}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Button
                  onClick={() => setOpen(true)}
                  startIcon={<FilterIcon />}
                  fullWidth
                  size="small"
                  variant="outlined"
                >
                  {t("Search_FilterButtonLabel")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" color="secondary" fullWidth>
                  <FormLabel>{t("Search_SortLabel")}</FormLabel>
                  <OrderSelect
                    value={search.filter?.selection.sorting}
                    onChange={(sorting) =>
                      dispatch(
                        updateFilter(
                          {
                            type: FilterUpdateType.Sorting,
                            sorting,
                          },
                          true
                        )
                      )
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Dialog
              open={open}
              onClose={() => setOpen(false)}
              maxWidth="sm"
              fullWidth
            >
              <DialogTitle>{t("Search_FilterDialogTitle")}</DialogTitle>
              <DialogContent>
                <Fullbleed isEnabled>
                  <Filter filter={filter} />
                </Fullbleed>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setOpen(false)}
                  fullWidth
                >
                  {t("Search_FilterDialogButtonLabel", {
                    totalCount: search.results.totalCount,
                  })}
                </Button>
              </DialogActions>
            </Dialog>
          </Hidden>
        </Fullbleed>

        <div style={{ width: "100%" }}>
          <InfiniteScroll
            dataLength={search.results.totalLoaded}
            hasMore={search.newestPageNumber < search.results.lastPageNumber}
            next={() => dispatch(loadPage(search.newestPageNumber + 1))}
            loader={null}
            style={{ width: "100%", overflow: "hidden" }}
          >
            <Hidden xsDown>
              <Box mb={1} width="100%">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={9}>
                    <TextField
                      variant="outlined"
                      placeholder={t("ReferenceSearch_SearchFieldPlaceholder")}
                      fullWidth
                      size="small"
                      onClick={() => setOpen1(true)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <OrderSelect
                      value={search.filter?.selection.sorting}
                      onChange={(sorting) =>
                        dispatch(
                          updateFilter(
                            {
                              type: FilterUpdateType.Sorting,
                              sorting,
                            },
                            true
                          )
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            </Hidden>

            <Grid container spacing={2}>
              {search.results.pages
                .flatMap((page) => page.items)
                .map((item) => (
                  <Grid
                    item
                    xs={6}
                    md={3}
                    key={`${item.ringTypeDescription}#${item.reference}`}
                  >
                    <div
                      onClick={() => history.push(ringLink(item))}
                      className={classes.thumbnailContainer}
                    >
                      <Thumbnail src={item.previewImageUrl}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <Box mt={1}>
                            <Typography variant="body1" color="textPrimary">
                              {item.minPriceFormatted}
                              {item.minPriceFormatted !==
                                item.maxPriceFormatted && (
                                <span> - {item.maxPriceFormatted}</span>
                              )}
                            </Typography>
                          </Box>

                          <Box mt={0.5}>
                            <Typography variant="body2" color="textSecondary">
                              {item.reference}
                            </Typography>
                          </Box>

                          <img
                            src={item.collectionImageUrl}
                            alt={item.ringTypeDescription}
                            style={{
                              maxWidth: "60%",
                              marginTop: "16px",
                              marginBottom: "8px",
                            }}
                          />
                        </Box>
                      </Thumbnail>
                    </div>
                  </Grid>
                ))}
            </Grid>

            {!isLoading &&
              search.results.pages.flatMap((page) => page.items).length ===
                0 && (
                <Box mt={2}>
                  <Typography variant="h5">{t("Search_NoResults")}</Typography>
                </Box>
              )}
          </InfiniteScroll>
        </div>
      </div>

      <ReferenceSearchDialog open={open1} onClose={() => setOpen1(false)} />
    </Page>
  );
};

/* 

 * Can be removed if we change the backend
 */

const getFilter = (state: State): FilterInfo | null => {
  if (state.search.filter) {
    const categories = state.search.filter.categories.map((category) => {
      const foo = state.search.filter!.selection.categories.find(
        (c) => c.filterType === category.filterType
      )!.ids;

      const selectedIds = foo.length === category.items.length ? [] : foo;

      return {
        ...category,
        items: category.items.map((item) => {
          const isSelected = selectedIds.includes(item.id);

          return {
            ...item,
            isSelected,
            filterUpdate: {
              type: FilterUpdateType.Category as FilterUpdateType.Category,
              filterType: category.filterType,
              ids: isSelected
                ? selectedIds.filter((id) => id !== item.id)
                : [...selectedIds, item.id],
            },
          };
        }),
      };
    });

    return {
      ...state.search.filter,
      categories,
    };
  } else {
    return null;
  }
};
