import {
  Box,
  Button as MuiButton,
  ButtonProps,
  ListItemIcon,
  MenuItem,
  SvgIconProps,
} from "@material-ui/core";
import { RingView } from "configurator/model/model";
import { ringUrl } from "configurator/view/urls";
import React from "react";
import { useTranslation } from "react-i18next";

export type Button = {
  translationKey: string;
  buttonHtmlId: string;
  mainMenuSort: number | null;
  productMenuSort: number | null;
  productMenuAddUrlDetails: boolean;
  openInNewWindow: boolean;
  url: string;
  svgContent: string | null;
};

type Props = {
  buttons: Button[];
  sortingKey: "mainMenuSort" | "productMenuSort";
  ringView?: RingView;
  extraProps?: Partial<ButtonProps>;
  boxed?: boolean;
};

export const CustomButtons: React.FC<Props> = ({
  buttons,
  sortingKey,
  ringView,
  extraProps,
  boxed,
}: Props) => {
  const { t } = useTranslation();

  const sorted = buttons.sort((a, b) =>
    a[sortingKey]! < b[sortingKey]!
      ? -1
      : a[sortingKey]! > b[sortingKey]!
      ? 1
      : 0
  );

  const Wrapper: React.FC = ({ children }) => {
    if (!boxed) return <>{children}</>;
    return <Box mt={1}>{children}</Box>;
  };

  const targetProps = (button: Button) =>
    button.openInNewWindow
      ? { target: "_blank", rel: "noopener noreferrer" }
      : {};

  return (
    <>
      {sorted.map((button) => (
        <Wrapper key={button.buttonHtmlId}>
          <MuiButton
            id={button.buttonHtmlId}
            target={button.openInNewWindow ? "_blank" : undefined}
            href={
              button.productMenuAddUrlDetails && ringView
                ? button.url + ringUrl("", ringView, false)
                : button.url
            }
            {...targetProps(button)}
            {...extraProps}
          >
            {t(`btn_${button.translationKey}`)}
          </MuiButton>
        </Wrapper>
      ))}
    </>
  );
};

export const CustomMenuItems: React.FC<Props> = ({
  buttons,
  sortingKey,
}: Props) => {
  const { t } = useTranslation();

  const sorted = buttons.sort((a, b) =>
    a[sortingKey]! < b[sortingKey]!
      ? -1
      : a[sortingKey]! > b[sortingKey]!
      ? 1
      : 0
  );

  return (
    <>
      {sorted.map((button) => (
        <MenuItem
          id={button.buttonHtmlId}
          key={button.buttonHtmlId}
          divider
          onClick={() => {
            if (button.openInNewWindow) {
              window.open(button.url, "_blank");
            } else {
              window.location.href = button.url;
            }
          }}
        >
          <ListItemIcon>
            {button.svgContent && <CustomSvg content={button.svgContent} />}
          </ListItemIcon>
          {t(`btn_${button.translationKey}`)}
        </MenuItem>
      ))}
    </>
  );
};

const CustomSvg: React.FC<SvgIconProps & { content: string }> = (props) => (
  <svg
    width="24"
    height="24"
    /* This is a bit of a hack. Using SvgIcon didn't work */
    className="MuiSvgIcon-root"
    dangerouslySetInnerHTML={{ __html: props.content }}
  />
);
