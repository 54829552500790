import React, { useState } from "react";
import {
  Grid,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  Typography,
  Theme,
  makeStyles,
  useMediaQuery,
  useTheme,
  MenuList,
  MenuItem,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import RadioButtonChecked from "icons/RadioButtonChecked";
import RadioButtonUnchecked from "icons/RadioButtonUnchecked";
import { DialogExplanation } from "components/DialogExplanation";
import { Fullbleed } from "components/Fullbleed";
import {
  ConfiguratorValue,
  SelectedTypeValues,
  Value,
} from "configurator/model/model";
import ChevronRight from "icons/ChevronRight";
import Info from "icons/Info";

const useStyles = makeStyles((theme: Theme) => ({
  progress: {
    zIndex: theme.zIndex.tooltip + 1,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginLeft: "-20px",
    marginTop: "-20px",
  },
  content: {
    position: "relative",
    minHeight: "200px",
  },
  gridItem: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative",
    cursor: "pointer",
    background: "white",
  },
  gridItemHeader: {
    width: "100%",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    paddingBottom: "0px",
  },
  preview: {
    display: "block",
    marginBottom: theme.spacing(2),
    height: "auto",
    maxHeight: "350px",
    maxWidth: "100%",
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
  onClick: (value: ConfiguratorValue<Value>) => void;
  selectedTypeValues: SelectedTypeValues<Value> | null;
  title: string;
};

export const ConfigurationDialog: React.FC<Props> = ({
  open,
  onClose,
  onClick,
  selectedTypeValues,
  title,
}) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down("xs"));
  const classes = useStyles();
  const { t } = useTranslation();
  const [showAlternatives, setShowAlternatives] = useState(true);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent className={classes.content}>
        {selectedTypeValues && (
          <Fullbleed isEnabled>
            {!selectedTypeValues && (
              <CircularProgress className={classes.progress} />
            )}

            {selectedTypeValues.title && selectedTypeValues.description && (
              <DialogExplanation title={selectedTypeValues.title}>
                <Typography variant="body1">
                  {selectedTypeValues.description}
                </Typography>
              </DialogExplanation>
            )}

            {selectedTypeValues.values.some(
              ({ ring }) => ring.newValues.length > 0
            ) && (
              <DialogExplanation
                title={
                  showAlternatives
                    ? t("Ring_Alternative_Toggle_Title_Show")
                    : t("Ring_Alternative_Toggle_Title_Hide")
                }
              >
                <Typography
                  variant="body1"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowAlternatives(!showAlternatives)}
                >
                  <ChevronRight />{" "}
                  {showAlternatives
                    ? t("Ring_Alternative_Toggle_Button_Show")
                    : t("Ring_Alternative_Toggle_Button_Hide")}
                </Typography>
              </DialogExplanation>
            )}

            {!selectedTypeValues.useDropdown && (
              <Grid container>
                {selectedTypeValues.values
                  .filter((value) =>
                    showAlternatives ? true : value.ring.newValues.length === 0
                  )
                  .map((value, idx) => {
                    const numberOfColumns =
                      !isMobile &&
                      (selectedTypeValues.values.length === 3 ||
                        selectedTypeValues.values.length > 4)
                        ? 3
                        : 2;

                    return (
                      <Grid
                        item
                        key={value.value}
                        xs={numberOfColumns === 2 ? 6 : 4}
                        className={classes.gridItem}
                        style={{
                          borderRightWidth: Math.min(
                            1,
                            (idx + 1) % numberOfColumns
                          ),
                        }}
                        onClick={() => onClick(value)}
                      >
                        <div className={classes.gridItemHeader}>
                          <div />

                          <Typography
                            variant="body1"
                            dangerouslySetInnerHTML={{
                              __html: value.description,
                            }}
                          />

                          {selectedTypeValues.selectedValue === value.value ? (
                            <RadioButtonChecked />
                          ) : (
                            <RadioButtonUnchecked />
                          )}
                        </div>

                        <img
                          src={value.ring.previewImageUrl}
                          className={classes.preview}
                          alt=""
                        />

                        {value.ring.newValues.length > 0 && (
                          <div
                            style={{
                              position: "absolute",
                              bottom: "8px",
                              left: "16px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Info />{" "}
                            <span style={{ paddingLeft: "8px" }}>
                              {t("Ring_Alternative")}
                            </span>
                          </div>
                        )}
                      </Grid>
                    );
                  })}
              </Grid>
            )}

            {selectedTypeValues.useDropdown && (
              <>
                <MenuList disablePadding>
                  {selectedTypeValues.values.map((value) => (
                    <MenuItem
                      key={value.value}
                      onClick={() => onClick(value)}
                      divider
                    >
                      <ListItemIcon>
                        {selectedTypeValues.selectedValue === value.value ? (
                          <RadioButtonChecked />
                        ) : (
                          <RadioButtonUnchecked />
                        )}
                      </ListItemIcon>
                      <ListItemText primary={value.description} />
                    </MenuItem>
                  ))}
                </MenuList>
              </>
            )}
          </Fullbleed>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onClose} fullWidth>
          {t("Ring_Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
