function isObject<A>(item: A): boolean {
  return (
    item && typeof item === "object" && !Array.isArray(item) && item !== null
  );
}

export function deepMerge(target: any, source: any): any {
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!target[key] || !isObject(target[key])) {
          target[key] = source[key];
        }
        deepMerge(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    });
  }

  return target;
}
