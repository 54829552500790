import React from "react";
import cx from "classnames";
import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(-2),
  },
}));

type Props = {
  children: React.ReactNode;
  className?: string;
  isEnabled?: boolean;
};

export const Fullbleed = ({
  children,
  isEnabled = false,
  className,
}: Props) => {
  const classes = useStyles({});

  return (
    <div className={cx({ [classes.root]: isEnabled }, className)}>
      {children}
    </div>
  );
};
