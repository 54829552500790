export type Email = {
  toAddresses: string[];
  ccAddresses: string[];
  bccAddress: string | null;
  extraMessage: string;
};

export type RingSize = {
  ringSize: number;
  localized: string;
};

export interface RingIdentifier {
  reference: string;
  purity?: number | null;
  selectedRingSize?: RingSize | null;
}

export function areRingsEqual(left: RingIdentifier, right: RingIdentifier) {
  if (left.reference !== right.reference) return false;

  if (!left.purity && !right.purity) return true;

  if (left.purity && right.purity) {
    return left.purity === right.purity;
  }

  return false;
}
