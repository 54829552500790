import { createTheme, Theme } from "@material-ui/core";

import { deepMerge } from "utils/deepMerge";

export const createCustomTheme = (
  palette: Theme["palette"],
  typography: Theme["typography"],
  shape: Theme["shape"],
  breakpoints: Theme["breakpoints"],
  overrides: Theme["overrides"] = {}
) =>
  createTheme({
    palette,
    typography,
    shape,
    breakpoints,
    overrides: deepMerge(
      {
        MuiCssBaseline: {
          "@global": {
            html: {
              WebkitFontSmoothing: "auto",
            },
            body: {
              margin: 0,
              padding: 0,
            },
          },
        },
        MuiContainer: {
          root: {
            marginLeft: 0,
            marginRight: 0,
          },
        },
        MuiButton: {
          contained: {
            boxShadow: "none",
            padding: "12px",

            "&:hover": {
              boxShadow: "none",
            },

            "&:active": {
              boxShadow: "none",
            },
          },
          outlined: {
            padding: "12px",
          },
          containedPrimary: {
            color: palette.common.white,
          },
        },
        MuiList: {
          padding: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
        MuiListItemText: {
          multiline: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
        MuiListItemIcon: {
          root: {
            minWidth: "36px",
          },
        },
        MuiChip: {
          root: {
            fontSize: "18px",
          },
          sizeSmall: {
            fontSize: "16px",
            padding: "2px",
          },
        },
        MuiDialog: {
          root: {
            margin: 0,
          },
          paper: {
            margin: "8px",
            borderRadius: shape.borderRadius,
          },
          paperFullWidth: {
            width: "100%",
            height: "100%",

            [breakpoints.down("xs")]: {
              maxHeight: "calc(100% - 16px)",
            },
          },
          paperFullScreen: {
            height: "100%",
          },
        },
        MuiDialogTitle: {
          root: {
            borderBottom: `1px solid ${palette.divider}`,
            textAlign: "center",
            height: "60px",
            padding: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        },
        MuiDialogContent: {
          root: {
            padding: "16px",
            backgroundColor: palette.grey[100],
          },
        },
        MuiDialogActions: {
          root: {
            padding: 0,

            "& .MuiButton-root": {
              height: "60px",
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            },
          },
        },
        MuiSvgIcon: {
          root: {
            width: "24px",
            height: "24px",
            color: palette.primary.main,
          },
        },
        MuiCheckbox: {
          root: {
            padding: 0,
          },
        },
        MuiFormControlLabel: {
          root: {
            marginLeft: 0,
          },
        },
        MuiFormLabel: {
          root: {
            ...typography.h4,
            marginBottom: "8px",
            "&$focused": {
              color: palette.grey[700],
            },
          },
        },
        MuiSelect: {
          root: {
            background: palette.common.white,
            paddingTop: "8px",
            paddingBottom: "8px",
          },
          icon: {
            color: palette.primary.main,
          },
          select: {
            height: "24px",
            lineHeight: "24px",
          },
        },
        MuiOutlinedInput: {
          root: {
            background: palette.background.default,

            "&:hover $notchedOutline": {
              background: "rgba(0,0,0,0.05)",
              borderColor: palette.grey[400],
            },
          },
          notchedOutline: {
            borderColor: palette.grey[400],
          },
        },
        MuiDrawer: {
          paperAnchorLeft: {
            minWidth: "min(80%, 300px)",
          },
        },
        MuiCardHeader: {
          root: {
            padding: 0,
          },
          content: {
            display: "flex",
            alignItems: "center",
            height: "48px",
            paddingLeft: "16px",
          },
          title: {
            ...typography.h4,
          },
          action: {
            marginTop: 0,
            marginRight: "4px",
            height: "48px",
            display: "flex",
            alignItems: "center",
          },
        },
        MuiCardContent: {
          root: {
            "&:last-child": {
              paddingBottom: "16px",
            },
          },
        },
        MuiLink: {
          root: {
            cursor: "pointer",
          },
        },
      },
      overrides
    ),
  });
