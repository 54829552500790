import { skin as vdbvrSkin } from "./vdbvr";

import { skin as arnoldSkin } from "./arnold";
import { skin as galletClaeysSkin } from "./galletclaeys";
import { skin as geersSkin } from "./geers";
import { skin as kortrijksegoudcentraleSkin } from "./kortrijksegoudcentrale";
import { skin as limpachSkin } from "./limpach";
import { skin as luytenSkin } from "./luyten";
import { skin as nevejanSkin } from "./nevejan";
import { skin as siebelSkin } from "./siebel";
import { skin as thierensVanWauweSkin } from "./thierensvanwauwe";
import { skin as vanParrysSkin } from "./vanparrys";
import { skin as vanRuyskensveldeSkin } from "./vanruyskensvelde2";
import { skin as versatoSkin } from "./versato";
import { skin as vranckenSkin } from "./vrancken";
import { skin as willemsSkin } from "./willems";

export const getSkinByKey = (key: string | null) => {
  switch (key) {
    case "vdbvr":
      return vdbvrSkin;
    case "arnold":
      return arnoldSkin;
    case "galletclaeys":
      return galletClaeysSkin;
    case "geers":
      return geersSkin;
    case "kortrijksegoudcentrale":
      return kortrijksegoudcentraleSkin;
    case "limpach":
      return limpachSkin;
    case "luyten":
      return luytenSkin;
    case "nevejan":
      return nevejanSkin;
    case "siebel":
      return siebelSkin;
    case "thierensvanwauwe":
      return thierensVanWauweSkin;
    case "vanparrys":
      return vanParrysSkin;
    case "vanruyskensvelde":
      return vanRuyskensveldeSkin;
    case "versato":
      return versatoSkin;
    case "vrancken":
      return vranckenSkin;
    case "willems":
      return willemsSkin;
    default:
      return vdbvrSkin;
  }
};
