import { RingView } from "./model";
import { ThunkAction } from "redux-thunk";
import axios from "axios";
import { State } from "reducer";
import { Email } from "models";

export const ACTION = {
  LOAD_RING_REQUEST: "LOAD_RING_REQUEST",
  LOAD_RING_SUCCESS: "LOAD_RING_SUCCESS",
} as const;

export type Action = LoadRingRequestAction | LoadRingSuccessAction;

type LoadRingRequestAction = {
  type: typeof ACTION.LOAD_RING_REQUEST;
  payload: {
    updateType: ConfiguratorUpdateType;
  };
};

type LoadRingSuccessAction = {
  type: typeof ACTION.LOAD_RING_SUCCESS;
  payload: {
    ringView: RingView;
  };
};

export const loadRingRequest = (
  updateType: ConfiguratorUpdateType
): LoadRingRequestAction => ({
  type: ACTION.LOAD_RING_REQUEST,
  payload: { updateType },
});

export const loadRingSuccess = (ringView: RingView): LoadRingSuccessAction => ({
  type: ACTION.LOAD_RING_SUCCESS,
  payload: {
    ringView,
  },
});

// All these type of updates will return the same result from api/configurator

export enum ConfiguratorUpdateType {
  Initial,
  Reference,
  Purity,
  ConfigurationType,
  RingSize,
}

type ConfiguratorUpdate =
  | {
      type: ConfiguratorUpdateType.Initial;
      reference: string;
      purity: number | null;
      ringSize: number | null;
      selectedConfigurationType: string | null;
    }
  | { type: ConfiguratorUpdateType.Reference; reference: string }
  | { type: ConfiguratorUpdateType.Purity; purityInCarat: number }
  | { type: ConfiguratorUpdateType.RingSize; ringSize: number }
  | {
      type: ConfiguratorUpdateType.ConfigurationType;
      configurationTypeKey: string;
    };

export function updateConfigurator(
  update: ConfiguratorUpdate
): ThunkAction<Promise<RingView>, State, {}, Action> {
  return async (dispatch, getState) => {
    try {
      const { configurator } = getState();

      dispatch(loadRingRequest(update.type));

      const response = await axios.post<{
        ringView: RingView;
      }>(`/api/configurator`, makeRequest(update, configurator.ringView));

      dispatch(loadRingSuccess(response.data.ringView));

      return response.data.ringView;
    } catch (error) {
      // dispatch(failed(error))
      throw error;
    }
  };
}

interface ConfiguratorRequest {
  reference: string;
  purity: number | null;
  selectedConfigurationType: string | null;
  selectedRingSize: number | null;
}

function makeRequest(
  update: ConfiguratorUpdate,
  ringView: RingView | null
): Partial<ConfiguratorRequest> {
  // We fill in the defaults, so we don't always have to pass these in the components

  const existing = ringView
    ? {
        reference: ringView.reference,
        purity: ringView.purity,
        selectedConfigurationType: ringView.selectedConfigurationType,
        selectedRingSize: ringView.selectedRingSize?.ringSize,
      }
    : {};

  switch (update.type) {
    case ConfiguratorUpdateType.Initial:
      return {
        ...existing,
        reference: update.reference,
        purity: update.purity,
        selectedRingSize: update.ringSize,
        selectedConfigurationType: update.selectedConfigurationType,
      };
    case ConfiguratorUpdateType.Reference:
      return { ...existing, reference: update.reference };
    case ConfiguratorUpdateType.Purity:
      return {
        ...existing,
        purity: update.purityInCarat,
      };
    case ConfiguratorUpdateType.RingSize:
      return {
        ...existing,
        selectedRingSize: update.ringSize,
      };
    case ConfiguratorUpdateType.ConfigurationType:
      return {
        ...existing,
        selectedConfigurationType: update.configurationTypeKey,
      };
  }
}

// TODO Legacy, need to be reworked after deploying
type EmailRequest = Email & {
  reference: string;
  purity: number | null;
  selectedRingSize: string | null;
  url: string;
};

export function sendEmail(
  email: Email
): ThunkAction<Promise<void>, State, {}, Action> {
  return async (_, getState) => {
    const {
      configurator: { ringView },
    } = getState();

    if (!ringView) return;

    const emailRequest: EmailRequest = {
      ...email,
      reference: ringView.reference,
      purity: ringView.purity,
      selectedRingSize: ringView.selectedRingSize?.localized || null,
      url: window.location.href,
    };

    // Show result/success?

    await axios.post(`/api/email/ring`, emailRequest);
  };
}
