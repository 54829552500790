import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router";

import { LoginPage } from "./LoginPage";
import { RegisterPage } from "./RegisterPage";
import { ChangePasswordPage } from "./ChangePasswordPage";
import { ProfilePage } from "./ProfilePage";

const PATH = {
  LOGIN: "login",
  REGISTER: "register",
  CHANGE_PASSWORD: "change-password",
  PROFILE: "profile",
};

export default ({ match }: RouteComponentProps) => (
  <Switch>
    <Route component={LoginPage} path={`${match.path}/${PATH.LOGIN}`} />
    <Route component={RegisterPage} path={`${match.path}/${PATH.REGISTER}`} />
    <Route
      component={ChangePasswordPage}
      path={`${match.path}/${PATH.CHANGE_PASSWORD}`}
    />
    <Route component={ProfilePage} path={`${match.path}/${PATH.PROFILE}`} />
  </Switch>
);
