import React from "react";

import { makeStyles, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    width: "100%",
    height: "fit-content",
  },
  explanation: {
    border: `1px solid ${theme.palette.primary.main}`,
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  title: {
    marginBottom: theme.spacing(1),
  },
}));

export const DialogExplanation: React.FC<{
  title?: string;
  children: React.ReactNode;
}> = ({ title, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.explanation}>
        {title && (
          <Typography variant="h3" className={classes.title}>
            {title}
          </Typography>
        )}

        {children}
      </div>
    </div>
  );
};
