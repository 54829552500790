import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export default (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5352 11.9983L15 16.6676L13.4539 18L9 11.9978L13.4541 6L14.9998 7.33308L11.5352 11.9983Z"
    />
  </SvgIcon>
);
