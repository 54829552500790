import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export default (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4648 12.0017L9 7.33243L10.5461 6L15 12.0022L10.5459 18L9.00024 16.6669L12.4648 12.0017Z"
    />
  </SvgIcon>
);
