import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export default (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" {...props}>
    <g>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
    </g>
  </SvgIcon>
);
