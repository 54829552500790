import React from "react";
import { MenuItem, Select } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { Sorting, SortOrder, SortType } from "search/model/model";

import ExpandMore from "icons/ExpandMore";

const SIMPLIFIED_SORT_ORDERS = {
  Default: {
    sortType: SortType.Default,
    sortOrder: SortOrder.Ascending,
  },
  PriceAscending: {
    sortType: SortType.Price,
    sortOrder: SortOrder.Ascending,
  },
  PriceDescending: {
    sortType: SortType.Price,
    sortOrder: SortOrder.Descending,
  },
  Popularity: {
    sortType: SortType.Popularity,
    sortOrder: SortOrder.Descending,
  },
  NewlyAdded: {
    sortType: SortType.NewlyAdded,
    sortOrder: SortOrder.Descending,
  },
} as const;

type SimplifiedSortOrders = keyof typeof SIMPLIFIED_SORT_ORDERS;

const toSimplifiedSortOrder = (
  sorting: Sorting | undefined
): SimplifiedSortOrders => {
  switch (sorting?.sortType) {
    case SortType.Default:
      return "Default";
    case SortType.Popularity:
      return "Popularity";
    case SortType.NewlyAdded:
      return "NewlyAdded";
    default:
      switch (sorting?.sortOrder) {
        case SortOrder.Descending:
          return "PriceDescending";
        default:
          return "PriceAscending";
      }
  }
};

type Props = {
  value?: Sorting;
  onChange: (value: Sorting) => void;
};

export const OrderSelect: React.FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation();

  return (
    <Select
      value={toSimplifiedSortOrder(value)}
      onChange={(e) =>
        onChange(SIMPLIFIED_SORT_ORDERS[e.target.value as SimplifiedSortOrders])
      }
      variant="outlined"
      IconComponent={ExpandMore}
      fullWidth
    >
      {Object.keys(SIMPLIFIED_SORT_ORDERS).map((sorting) => (
        <MenuItem value={sorting} key={sorting}>
          {t(`Search_Sorting_${sorting}`)}
        </MenuItem>
      ))}
    </Select>
  );
};
