import { Notyf } from "notyf";
import "notyf/notyf.min.css";

export default new Notyf({
  duration: 4000,
  position: {
    x: "center",
    y: "bottom",
  },
});
