import queryString from "query-string";

import {
  FilterInfo,
  FilterType,
  SortOrder,
  SortType,
  UrlFilterSelection,
} from "search/model/model";

interface SearchQuery {
  min: number | null;
  max: number | null;
  sto: number[] | number | null;
  mat: number[] | number | null;
  col: number[] | number | null;
  tag: number[] | number | null;
  sortType: SortType | null;
  sortOrder: SortOrder | null;
}

const format = { arrayFormat: "separator" as const, arrayFormatSeparator: "|" };

export const searchUrl = (filter: FilterInfo) =>
  queryString.stringifyUrl(
    {
      url: "search",
      query: createQuery(filter) as any,
    },
    { skipNull: true, ...format }
  );

function createQuery(filter: FilterInfo): SearchQuery {
  return {
    min:
      filter.selection.priceRange.min !== filter.priceRange.min
        ? filter.selection.priceRange.min
        : null,
    max:
      filter.selection.priceRange.max !== filter.priceRange.max
        ? filter.selection.priceRange.max
        : null,
    sto: categories(filter, FilterType.Stones),
    mat: categories(filter, FilterType.Materials),
    col: categories(filter, FilterType.Collections),
    tag: categories(filter, FilterType.Tags),
    sortOrder:
      filter.selection.sorting.sortOrder !== SortOrder.Ascending
        ? filter.selection.sorting.sortOrder
        : null,
    sortType:
      filter.selection.sorting.sortType !== SortType.Default
        ? filter.selection.sorting.sortType
        : null,
  };
}

const categories = (filter: FilterInfo, type: FilterType) => {
  const all = filter.categories.find((c) => c.filterType === type);

  const selected =
    filter.selection.categories.find((c) => c.filterType === type)?.ids ?? [];

  return all?.items.length === selected.length ? null : selected;
};

export const parseSearchQuery = (query: string): UrlFilterSelection | null => {
  const parsed = queryString.parse(query, { parseNumbers: true, ...format });

  if (Object.keys(parsed).length === 0) return null;

  const searchQuery = (parsed as unknown) as SearchQuery;

  const cat = (type: FilterType, ids: number[] | number | null) => {
    const arr = (typeof ids === "number" ? [ids] : ids) ?? [];

    return ids ? [{ filterType: type, ids: arr }] : [];
  };

  const categories = [
    ...cat(FilterType.Materials, searchQuery.mat),
    ...cat(FilterType.Stones, searchQuery.sto),
    ...cat(FilterType.Collections, searchQuery.col),
    ...cat(FilterType.Tags, searchQuery.tag),
  ];

  return {
    priceRange: {
      min: searchQuery.min,
      max: searchQuery.max,
    },
    categories,
    sorting: {
      sortType: searchQuery.sortType,
      sortOrder: searchQuery.sortOrder,
    },
  };
};
