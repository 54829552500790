import React from "react";

export default () => (
  <svg width="24" height="24" color="green" style={{ overflow: "unset" }}>
    <rect
      x="0.5"
      y="0.5"
      width="23"
      height="23"
      rx="11.5"
      stroke="currentColor"
      fill="none"
    />
    <rect x="7" y="7" width="10" height="10" rx="5" fill="currentColor" />
  </svg>
);
