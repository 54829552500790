/* eslint global-require: 0 */
/* eslint no-underscore-dangle: 0 */
import { applyMiddleware, compose, createStore } from "redux";
import thunkMiddleware, { ThunkMiddleware } from "redux-thunk";
import { useDispatch } from "react-redux";

import { Action } from "./actions";
import reducer, { State } from "./reducer";

const devToolsEnhancer = global.__REDUX_DEVTOOLS_EXTENSION__
  ? global.__REDUX_DEVTOOLS_EXTENSION__()
  : (x: any) => x;

const store = createStore(
  reducer,
  undefined,
  compose(
    applyMiddleware(thunkMiddleware as ThunkMiddleware<State, Action, {}>),
    devToolsEnhancer
  )
);

// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept("./reducer", () => {
    const nextRootReducer = require("./reducer").default;

    store.replaceReducer(nextRootReducer);
  });
}

export default store;

export const useThunkDispatch = () => useDispatch<typeof store.dispatch>();
