import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export default (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" {...props}>
    <path
      fillRule="evenodd"
      d="M3 19v2h18v-2s-1.705-3.596-6.267-4.689a11.693 11.693 0 0 0-2.82-.31 11.6 11.6 0 0 0-2.762.339C4.676 15.465 3 19 3 19zm6.46-6.774a4.457 4.457 0 0 0 2.54.73 4.473 4.473 0 0 0 4.39-4.477 4.478 4.478 0 1 0-6.93 3.747zM18.617 19H5.384c.24-.302.554-.65.95-.998C7.447 17.024 9.24 16 12 16c2.76 0 4.554 1.024 5.665 2.002.397.349.711.697.95.998zM9.434 8.478a2.478 2.478 0 1 0 4.956 0 2.478 2.478 0 0 0-4.956 0z"
      clipRule="evenodd"
    />
  </SvgIcon>
);
