import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export default (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" {...props}>
    <path
      d="M5 16.084V19h2.916l8.601-8.601-2.916-2.916L5 16.083zm13.773-7.94a.774.774 0 0 0 0-1.097l-1.82-1.82a.774.774 0 0 0-1.097 0l-1.423 1.424 2.916 2.916 1.424-1.423z"
      fill="currentColor"
    />
  </SvgIcon>
);
