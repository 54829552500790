import axios from "axios";
import { State } from "reducer";
import { ThunkAction } from "redux-thunk";
import {
  emptyWishListSuccess,
  EmptyWishListSuccessAction,
} from "wishlist/model/actions";

export const ACTION = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
} as const;

export type Action = LoginAction | LogoutAction;

type LoginAction = {
  type: typeof ACTION.LOGIN;
  username: string;
};

type LogoutAction = {
  type: typeof ACTION.LOGOUT;
};

export const login = (username: string) => ({
  type: ACTION.LOGIN,
  username,
});

export const logout = () => ({
  type: ACTION.LOGOUT,
});

export function loginAndRedirect(
  username: string,
  password: string,
  redirectUrl: string
): ThunkAction<Promise<void>, State, {}, Action> {
  return async (dispatch) => {
    await axios.post("/api/account/login", { username, password });
    dispatch(login(username));
    window.location.href = redirectUrl;
    window.location.reload();
  };
}

export function logoutAndRedirect(
  redirectUrl: string
): ThunkAction<Promise<void>, State, {}, Action | EmptyWishListSuccessAction> {
  return async (dispatch) => {
    await axios.post("/api/account/logout");
    dispatch(logout());
    dispatch(emptyWishListSuccess());
    window.location.href = redirectUrl;
  };
}
