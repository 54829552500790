import React from "react";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { RouteComponentProps } from "react-router";

import { Page } from "components/Page";
import { TextFieldInput } from "components/TextFieldInput";
import PasswordInput from "components/PasswordInput";
import { loginAndRedirect } from "account/model/actions";
import { useDispatch } from "react-redux";

type FormValues = {
  username: string;
  password: string;
};

const initialValues: FormValues = {
  username: "",
  password: "",
};

const createValidationSchema = (t: TFunction) =>
  Yup.object({
    username: Yup.string().required(t("Account_Login_Username_Required")),
    password: Yup.string().required(t("Account_Login_Password_Required")),
  });

export const LoginPage = ({ location, history }: RouteComponentProps) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const returnUrl =
    new URLSearchParams(location.search).get("returnUrl") || "/#/search";

  const onSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>
  ) => {
    (dispatch(
      loginAndRedirect(values.username, values.password, returnUrl)
    ) as any).catch(() =>
      helpers.setFieldError("username", "Ongeldige combinatie")
    );
  };

  const validationSchema = createValidationSchema(t);

  // TODO: Clean up the exists

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <Page isLoading={isSubmitting} sidebar={true}>
          <Container maxWidth="sm">
            <Form>
              <Grid container spacing={2} direction="column">
                {!i18n.exists("Account_Login_Title") && (
                  <Grid item>
                    <Typography variant="h3">
                      {t("Account_LocalAccount")}
                    </Typography>
                  </Grid>
                )}
                {i18n.exists("Account_Login_Title") && (
                  <>
                    <Grid item>
                      <Typography variant="h3">
                        {t("Account_Login_Title")}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        {t("Account_LocalAccount")}
                      </Typography>
                    </Grid>
                  </>
                )}
                <Grid item>
                  <TextFieldInput
                    name="username"
                    variant="outlined"
                    label={t("Account_Login_User_name")}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <PasswordInput
                    name="password"
                    variant="outlined"
                    label={t("Account_Login_Password")}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                  >
                    {t("Account_LogIn")}
                  </Button>
                </Grid>
              </Grid>

              <Box mt={4}>
                {!i18n.exists("Account_Login_Register_Title") && (
                  <Typography variant="body1">
                    <Link
                      variant="body1"
                      onClick={() =>
                        history.push(`/account/register?returnUrl=${returnUrl}`)
                      }
                    >
                      {t("Account_Register")}
                    </Link>{" "}
                    {t("Account_IfNoAccount")}
                  </Typography>
                )}
                {i18n.exists("Account_Login_Register_Title") && (
                  <Grid container spacing={2} direction="column">
                    <Grid item>
                      <Typography variant="h3">
                        {t("Account_Login_Register_Title")}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        {t("Account_Login_Register")}{" "}
                        <Link
                          variant="body1"
                          onClick={() =>
                            history.push(
                              `/account/register?returnUrl=${returnUrl}`
                            )
                          }
                        >
                          {t("Account_Login_Register_Link")}
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Form>
          </Container>
        </Page>
      )}
    </Formik>
  );
};
