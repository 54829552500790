import { WishListItem } from "./model";
import { ACTION, Action } from "./actions";

export type State = {
  readonly isLoading: boolean;
  readonly isUpdating: boolean;
  readonly items: WishListItem[];
};

const defaultState = {
  isLoading: true,
  isUpdating: false,
  items: [],
};

export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case ACTION.LOAD_WISHLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    case ACTION.UPDATE_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case ACTION.ADD_ITEM_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        items: [...state.items, action.payload.item],
      };
    case ACTION.REMOVE_ITEM_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        items: state.items.filter((i) => i.id !== action.payload.id),
      };
    case ACTION.EMPTY_WISHLIST_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        items: [],
      };
    default:
      return state;
  }
};
