import React, { useState } from "react";
import {
  InputAdornment,
  IconButton,
  TextField,
  TextFieldProps,
} from "@material-ui/core";
import { FieldInputProps, useField } from "formik";

import VisibilityOff from "icons/VisibilityOff";
import VisibilityOn from "icons/VisibilityOn";

type Props = Omit<
  TextFieldProps,
  keyof FieldInputProps<string> | "type" | "error" | "InputProps"
> & {
  name: string;
  showStrength?: boolean;
};

const PasswordInput: React.FC<Props> = ({ name, helperText, ...props }) => {
  const [field, meta] = useField<string>(name);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      {...props}
      {...field}
      type={showPassword ? "text" : "password"}
      error={!!meta.error && meta.touched}
      helperText={meta.touched && meta.error ? meta.error : helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <VisibilityOff /> : <VisibilityOn />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordInput;
