import React from "react";
import { FieldInputProps, useField } from "formik";
import { TextField, TextFieldProps } from "@material-ui/core";

type Props = Omit<TextFieldProps, keyof FieldInputProps<string> | "error"> & {
  name: string;
};

export const TextFieldInput: React.FC<Props> = ({
  name,
  helperText,
  ...props
}: Props) => {
  const [field, meta] = useField(name);

  return (
    <TextField
      {...props}
      {...field}
      error={!!meta.error && meta.touched}
      helperText={meta.touched && meta.error ? meta.error : helperText}
    />
  );
};
