import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import createPalette from "@material-ui/core/styles/createPalette";
import createTypography from "@material-ui/core/styles/createTypography";
import { Overrides } from "@material-ui/core/styles/overrides";
import { Skin } from "themes/model";
import { createCustomTheme } from "./createCustomTheme";

const logoPath =
  "https://ringconfigimg.blob.core.windows.net/skins/VanParrys/logo2.png";

const headerPath =
  "https://ringconfigimg.blob.core.windows.net/skins/VanParrys/bg.jpg";

export const palette = createPalette({
  common: {
    white: "#FFFFFF",
    black: "#4F4F4F",
  },
  primary: {
    light: "#F9F8F7",
    main: "#C4BEB5",
  },
  text: {
    primary: "#000000",
    secondary: "#666666",
  },
  error: {
    light: "#FF5833",
    main: "#E43A15",
    dark: "#D02601",
  },
  warning: {
    light: "#FF893E",
    main: "#EF6B20",
    dark: "#DB570C",
  },
  info: {
    light: "#1E97FD",
    main: "#0079DF",
    dark: "#0065CB",
  },
  success: {
    light: "#1EC669",
    main: "#00A84B",
    dark: "#009437",
  },
  background: {
    default: "#ffffff",
  },
  grey: {
    100: "#F7F7F7",
    200: "#EEEEEE",
    300: "#E7E7E7",
    400: "#E4E2E3",
    500: "#B2B2B2",
    600: "#8C8C8C",
    700: "#666666",
    800: "#333333",
    900: "#1F1F1F",
  },
  divider: "#E4E2E3",
});

const fontFamily = "'Poppins', sans-serif";

const weightNormal = 300;
const weightBold = 600;

export const typography = createTypography(palette, {
  h1: {
    fontStyle: "normal",
    fontWeight: weightNormal,
    fontFamily,
    fontSize: 33,
    lineHeight: 1,
    letterSpacing: 0,
  },
  h2: {
    fontStyle: "normal",
    fontWeight: 700,
    fontFamily,
    fontSize: "26px",
    lineHeight: 1,
    letterSpacing: 0,
  },
  h3: {
    fontStyle: "normal",
    fontWeight: weightNormal,
    fontFamily,
    fontSize: "20px",
    lineHeight: 1,
    letterSpacing: 0,
  },
  h4: {
    fontFamily,
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: 1,
    fontWeight: weightNormal,
    letterSpacing: 0,
  },
  h5: {
    fontFamily,
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: 1.2,
    fontWeight: weightBold,
    letterSpacing: 0,
  },
  h6: {
    fontFamily,
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: weightBold,
    lineHeight: 1,
    letterSpacing: 0,
  },
  subtitle1: {
    fontFamily,
    fontStyle: "normal",
    fontSize: "12px",
    lineHeight: 1.2,
    fontWeight: weightNormal,
    letterSpacing: 0,
  },
  subtitle2: {
    fontFamily,
    fontStyle: "normal",
    fontSize: "22px",
    lineHeight: 1.2,
    fontWeight: weightNormal,
    letterSpacing: 0,
    textTransform: "uppercase" as "uppercase",
  },
  button: {
    fontFamily,
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: weightBold,
    lineHeight: 1,
    letterSpacing: "1px",
  },
  caption: {
    fontFamily,
    fontSize: "12px",
    fontWeight: weightNormal,
    lineHeight: 1.4,
    letterSpacing: 0,
  },
  body1: {
    fontFamily,
    fontSize: "16px",
    fontWeight: weightNormal,
    lineHeight: 1.4,
    letterSpacing: 0,
  },
  body2: {
    fontFamily,
    fontSize: "14px",
    fontWeight: weightNormal,
    lineHeight: 1.4,
    letterSpacing: 0,
    "-webkit-font-smoothing": "subpixel-antialiased",
  },
});

const shape = {
  borderRadius: 0,
};

const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1206,
    xl: 1920,
  },
});

const overrides: Overrides = {
  MuiCssBaseline: {
    "@global": {
      "@font-face": [
        {
          fontFamily: "Poppins",
          fontDisplay: "auto",
          fontStyle: "normal",
          fontWeight: 300,
          src: `url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1JlFc-K.woff2) format('woff2')`,
          unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF",
        },
        {
          fontFamily: "Poppins",
          fontDisplay: "auto",
          fontStyle: "normal",
          fontWeight: 300,
          src: `url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlFQ.woff2) format('woff2')`,
          unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD",
        },
        {
          fontFamily: "Poppins",
          fontDisplay: "auto",
          fontStyle: "normal",
          fontWeight: 600,
          src: `url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2) format('woff2')`,
          unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF",
        },
        {
          fontFamily: "Poppins",
          fontDisplay: "auto",
          fontStyle: "normal",
          fontWeight: 600,
          src: `url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2) format('woff2')`,
          unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD",
        },
      ],
      ".value-icon":
      {
        height: ".75em"
      },
    },
  },
  MuiAppBar: { 
    colorDefault: { 
      backgroundColor: "#FFFFFF",
    },
  },
  MuiButton: {
    label: {
      color: "#4F4F4F",
    },
    containedPrimary: { 
      backgroundColor: "#FFC8A9",
    },
  },
};

export const skin: Skin = {
  logoPath,
  headerPath,
  headerHeight: 100,
  theme: createCustomTheme(palette, typography, shape, breakpoints, overrides),
};
