import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Box,
  Button,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { Page } from "components/Page";
import { Email } from "models";
import { State } from "reducer";

import FavoriteMin from "icons/FavoriteMin";
import { Thumbnail } from "configurator/view/Thumbnail";
import { removeItem, sendEmail } from "wishlist/model/actions";
import { ringLink } from "configurator/view/urls";
import { EmailDialog } from "components/EmailDialog";

export const WishListPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  const wishList = useSelector((rootState: State) => rootState.wishList);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isEmailModalOpen, setIsEmailModalOpen] = useState<boolean>(false);

  const onSubmit = (email: Email) => dispatch(sendEmail(email));

  const isLoading = wishList.isLoading || wishList.isUpdating;

  const Sidebar = () => {
    if (wishList.items.length > 0) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsEmailModalOpen(true)}
              fullWidth
            >
              {t("WishList_EmailList")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => window.print()}
              fullWidth
            >
              {t("WishList_PrintList")}
            </Button>
          </Grid>
        </Grid>
      );
    }

    return null;
  };

  return (
    <Page isLoading={isLoading} sidebar={<Sidebar />}>
      {!isLoading && wishList.items.length === 0 && (
        <Typography variant="h5">{t("WishList_NoResults")}</Typography>
      )}

      {wishList.items.length > 0 && (
        <Grid container spacing={2}>
          {wishList.items.map((item) => (
            <Grid item xs={12} sm={6} md={4}>
              <Card>
                <CardHeader
                  title={
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push(ringLink(item))}
                    >
                      {item.reference}
                    </span>
                  }
                  action={
                    <IconButton onClick={() => dispatch(removeItem(item.id))}>
                      <FavoriteMin />
                    </IconButton>
                  }
                />
                <Divider />
                <CardContent>
                  <Box mb={2}>
                    <Thumbnail src={item.previewImageUrl} />
                  </Box>
                  {item.price.additionalCurrencyPriceInfo && (
                    <Typography variant="h6">
                      {t("Ring_Price")}:{" "}
                      {item.price.additionalCurrencyPriceInfo.formattedPrice}
                    </Typography>
                  )}
                  <Typography variant="h6">
                    {t("Ring_Price")}:{" "}
                    {item.price.standardCurrencyPriceInfo.formattedPrice}
                  </Typography>
                  <Typography variant="body2">
                    {t("Ring_PriceValidDate")} {item.price.currentDate}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      <EmailDialog
        open={isEmailModalOpen}
        onClose={() => setIsEmailModalOpen(false)}
        //@ts-ignore
        onSubmit={onSubmit}
        explanation={t("EmailDialog_Text")}
        title={t("EmailDialog_Title")}
      />
    </Page>
  );
};
