import React from "react";
import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    height: "100%",
  },
  inner: {
    borderRadius: theme.shape.borderRadius,
    position: "relative",
    overflow: "hidden",
  },
  img: {
    display: "block",
    height: "auto",
    maxHeight: "350px",
    maxWidth: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "-12px",
    marginBottom: "-12px",
    [theme.breakpoints.up("xs")]: {
      marginTop: "-8px",
      marginBottom: "-8px",
    },
  },
}));

export const Thumbnail: React.FC<{
  src: string;
  children?: any;
  inline?: any;
}> = ({ src, children, inline }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {inline}

      <div className={classes.inner}>
        <img src={src} className={classes.img} alt="" />

        {children}
      </div>
    </div>
  );
};
