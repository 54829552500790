import React, { useState } from "react";
import {
  Toolbar,
  IconButton,
  Badge,
  Theme,
  useMediaQuery,
  useTheme,
  makeStyles,
  CircularProgress,
  Drawer,
  MenuList,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Box,
  AppBar,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Fullbleed } from "components/Fullbleed";
import Favorite from "icons/Favorite";
import ChevronLeft from "icons/ChevronLeft";
import Menu from "icons/Menu";
import Search from "icons/Search";
import Settings from "icons/Settings";
import Logout from "icons/Logout";
import Calendar from "icons/Calendar";
import Docs from "icons/Docs";
import User from "icons/User";

import { State } from "reducer";
import { serverData } from "serverData";
import { logoutAndRedirect } from "account/model/actions";
import { Desktop } from "./Desktop";
import skin from "skin";
import { MobileMenuSelector } from "components/Page/MobileMenuSelector";
import Country from "icons/Country";
import Language from "icons/Language";
import Currency from "icons/Currency";
import { CustomMenuItems } from "components/CustomButtons";

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    top: 0,
    left: 0,
    width: "100vw",
    zIndex: 10,
  },
  logo: {
    maxWidth: "70vw",
    maxHeight: "36px",
  },
  spacer: theme.mixins.toolbar,
  progress: {
    zIndex: theme.zIndex.tooltip + 1,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginLeft: "-20px",
    marginTop: "-20px",
  },
  content: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      minHeight: `calc(100% - ${skin.headerHeight + 16}px)`,
    },
  },
  sidebar: {
    marginBottom: 0,
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(2),
    minHeight: `calc(100% - ${skin.headerHeight + 64}px)`,
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
      minWidth: "225px",
      maxWidth: "225px",
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "300px",
      maxWidth: "300px",
    },
  },
}));

type Props = {
  isLoading: boolean;
  children: React.ReactNode;
  sidebar?: React.ReactNode;
  onBack?: () => void;
  onReferenceSearch?: () => void;
};

export const Page: React.FC<Props> = ({
  isLoading,
  children,
  onBack,
  onReferenceSearch,
  sidebar,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isPrint = useMediaQuery("@media print");
  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));
  const classes = useStyles();
  const history = useHistory();
  const numberOfWishlistItems = useSelector(
    (state: State) => state.wishList.items.length
  );
  const username = useSelector((s: State) => s.account.username);
  const isFullscreen = useMediaQuery("(min-width:1206px)");
  const [open, setOpen] = useState<boolean>(false);

  if (isPrint) {
    return <>{children}</>;
  }

  const doLogout = async () => {
    dispatch(logoutAndRedirect("/#/search"));
  };

  const onLogoClick = () => {
    if (serverData.logoUrl) {
      window.location.href = serverData.logoUrl;
    } else {
      // Not going to search for now on mobile - might be annoying
      // history.push("/search");
    }
  };

  if (isMobile) {
    return (
      <div style={{ overflow: "auto" }}>
        <AppBar color="default" elevation={3} position="fixed">
          <Toolbar className={classes.toolbar}>
            {onBack && (
              <IconButton edge="start" onClick={onBack}>
                <ChevronLeft style={{ width: "24px", height: "24px" }} />
              </IconButton>
            )}

            {!onBack && (
              <>
                <IconButton edge="start" onClick={() => setOpen(true)}>
                  <Menu />
                </IconButton>

                <Drawer
                  anchor="left"
                  open={open}
                  onClose={() => setOpen(false)}
                >
                  <Box m={2}>
                    <Typography variant="h2">{t("Profile_Welcome")}</Typography>
                    <Typography variant="h3">{username}</Typography>
                  </Box>

                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        setOpen(false);
                        history.push("/Search");
                      }}
                      divider
                    >
                      <ListItemIcon>
                        <Docs />
                      </ListItemIcon>
                      <ListItemText primary={t("Catalogus")} />
                    </MenuItem>
                    {serverData.makeAppointmentUrl && (
                      <MenuItem
                        onClick={() =>
                          (window.location.href = serverData.makeAppointmentUrl!)
                        }
                        divider
                      >
                        <ListItemIcon>
                          <Calendar />
                        </ListItemIcon>
                        <ListItemText primary={t("MakeAppointment")} />
                      </MenuItem>
                    )}

                    <CustomMenuItems
                      buttons={serverData.buttons.filter(
                        (b) => b.mainMenuSort !== null && b.mainMenuSort < 0
                      )}
                      sortingKey="mainMenuSort"
                    />
                    {username && (
                      <MenuItem
                        onClick={() => {
                          setOpen(false);
                          history.push("/WishList");
                        }}
                        divider
                      >
                        <ListItemIcon>
                          <Favorite />
                        </ListItemIcon>
                        <ListItemText primary={t("Wishlist")} />
                      </MenuItem>
                    )}
                    {onReferenceSearch && (
                      <MenuItem
                        onClick={onReferenceSearch}
                        divider={!!username}
                      >
                        <ListItemIcon>
                          <Search />
                        </ListItemIcon>
                        <ListItemText
                          primary={t("ReferenceSearch_SearchFieldPlaceholder")}
                        />
                      </MenuItem>
                    )}
                    {username && (
                      <MenuItem
                        onClick={() => {
                          setOpen(false);
                          history.push("/account/profile");
                        }}
                        divider
                      >
                        <ListItemIcon>
                          <Settings />
                        </ListItemIcon>
                        <ListItemText primary={t("Account_Settings")} />
                      </MenuItem>
                    )}
                    {username && (
                      <MenuItem onClick={doLogout} divider>
                        <ListItemIcon>
                          <Logout />
                        </ListItemIcon>
                        <ListItemText primary={t("LoginPartial_LogOff")} />
                      </MenuItem>
                    )}
                    {!username && (
                      <MenuItem
                        onClick={() => {
                          setOpen(false);
                          history.push("/account/login");
                        }}
                        divider
                      >
                        <ListItemIcon>
                          <User />
                        </ListItemIcon>
                        <ListItemText primary={t("LoginPartial_LogIn")} />
                      </MenuItem>
                    )}

                    <CustomMenuItems
                      buttons={serverData.buttons.filter(
                        (b) => b.mainMenuSort !== null && b.mainMenuSort > 0
                      )}
                      sortingKey="mainMenuSort"
                    />

                    <MobileMenuSelector
                      items={serverData.allLanguages}
                      selectedItem={serverData.language}
                      translationKey="Languages"
                      icon={<Language />}
                      urlBuilder={(code) => "/update-language?language=" + code}
                      divider={
                        serverData.allCountries.length > 1 ||
                        serverData.allCurrencies.length > 1
                      }
                    />

                    <MobileMenuSelector
                      items={serverData.allCountries}
                      selectedItem={serverData.country}
                      translationKey="Countries"
                      icon={<Country />}
                      urlBuilder={(code) => "/update-country?country=" + code}
                      divider={serverData.allCurrencies.length > 1}
                    />

                    <MobileMenuSelector
                      items={serverData.allCurrencies}
                      selectedItem={serverData.currency}
                      translationKey="Currencies"
                      icon={<Currency />}
                      urlBuilder={(code) => "/update-currency?currency=" + code}
                    />
                  </MenuList>
                </Drawer>
              </>
            )}

            <img
              src={skin.logoPath}
              alt=""
              className={classes.logo}
              onClick={onLogoClick}
            />

            <IconButton edge="end" onClick={() => history.push("/WishList")}>
              <Badge badgeContent={numberOfWishlistItems} color="primary">
                <Favorite style={{ width: "24px", height: "24px" }} />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>

        <div className={classes.spacer} />

        {isLoading && <CircularProgress className={classes.progress} />}

        <div style={{ margin: "16px" }}>{children}</div>
      </div>
    );
  } else {
    return (
      <Desktop numberOfWishlistItems={numberOfWishlistItems}>
        {isLoading && <CircularProgress className={classes.progress} />}

        <div
          className={classes.content}
          style={{
            margin: "16px",
            marginTop: 0,
            marginLeft: isFullscreen ? "0" : "16px",
            marginRight: isFullscreen ? "0" : "16px",
          }}
        >
          {sidebar && (
            <Fullbleed className={classes.sidebar} isEnabled={!isFullscreen}>
              {sidebar}
            </Fullbleed>
          )}

          {children}
        </div>
      </Desktop>
    );
  }
};
