import axios from "axios";
import i18next from "i18next";
import notifications from "notifications";

axios.interceptors.request.use((config) => {
  config.headers["X-Requested-With"] = "XMLHttpRequest";
  return config;
});

axios.interceptors.response.use(
  (resp) => resp,
  (error) => {
    if (error.response.status >= 500) {
      notifications.error(i18next.t("SomethingWentWrong_PleaseRefresh"));
    }

    return Promise.reject(error);
  }
);
