import React from "react";
import { Box, Typography, TypographyProps } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { RingView } from "configurator/model/model";

type Props = {
  ringView?: RingView;
  variant: TypographyProps["variant"];
  preferShort?: boolean;
};

export const ProductInformation: React.FC<Props> = ({
  ringView,
  variant,
  preferShort = false,
}) => {
  const { t } = useTranslation();

  if (
    ringView &&
    (ringView.ringCollection.shortDescription ||
      ringView.ringCollection.longDescription)
  ) {
    return (
      <>
        <Box mt={2}>
          <Typography variant="h3">{t("Ring_ProductInformation")}</Typography>
        </Box>
        <Box mt={1}>
          <Typography variant={variant} style={{ textAlign: "justify" }}>
            {preferShort
              ? ringView.ringCollection.shortDescription ||
                ringView.ringCollection.longDescription
              : ringView.ringCollection.longDescription ||
                ringView.ringCollection.shortDescription}
          </Typography>
        </Box>
      </>
    );
  }

  return null;
};
