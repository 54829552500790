import { FilterInfo, SearchResults } from "./model";
import { ACTION, Action } from "./actions";

export type State = {
  readonly isLoadingInitially: boolean;
  readonly isUpdatingFilter: boolean;
  readonly filterId: number;
  readonly filter: FilterInfo | null;
  readonly results: SearchResults;
  readonly newestPageNumber: number;
};

const defaultState: State = {
  isLoadingInitially: true,
  isUpdatingFilter: false,
  filterId: 0,
  filter: null,
  results: {
    lastPageNumber: 0,
    totalCount: 0,
    totalLoaded: 0,
    pages: [],
  },
  newestPageNumber: 0,
};

export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case ACTION.LOAD_INITIAL_SUCCESS:
      return {
        ...state,
        isLoadingInitially: false,
        ...action.payload,
      };
    case ACTION.UPDATE_FILTER_REQUEST:
      return {
        ...state,
        isUpdatingFilter: true,
        filterId: action.payload.filterId,
        filter: {
          ...state.filter!,
          selection: action.payload.selection,
        },
        newestPageNumber: 0,
      };
    case ACTION.UPDATE_FILTER_SUCCESS:
      if (action.payload.filterId === state.filterId) {
        return {
          ...state,
          isUpdatingFilter: false,
          filter: {
            ...state.filter!,
            selection: action.payload.selection,
          },
          results: action.payload.results,
        };
      } else return state;
    case ACTION.LOAD_PAGE_SUCCESS:
      if (action.payload.filterId === state.filterId) {
        return {
          ...state,
          results: {
            ...state.results,
            totalLoaded: action.payload.totalLoaded,
            pages: [...state.results.pages, action.payload.page],
          },
          newestPageNumber: action.payload.page.pageNumber,
        };
      } else return state;
    default:
      return state;
  }
};
