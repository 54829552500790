import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export default (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" {...props}>
    <path
      fillRule="evenodd"
      d="M8.623 2.66c.108-.718.71-1.26 1.457-1.26h3.84c.715 0 1.357.511 1.469 1.26l.001.008.283 2c.293.147.572.31.841.488l1.91-.767.028-.01c.601-.2 1.384-.019 1.773.67l1.926 3.335.009.016c.32.605.196 1.4-.38 1.86l-.008.008-1.588 1.24a7.263 7.263 0 0 1 0 .98l1.573 1.242c.606.464.726 1.267.381 1.888l-.004.007-1.921 3.326a1.474 1.474 0 0 1-1.82.653l-.01-.004-1.883-.757c-.266.176-.545.34-.839.487l-.28 1.987c-.092.784-.764 1.283-1.461 1.283h-3.84a1.488 1.488 0 0 1-1.46-1.212l-.006-.028-.287-2.029a7.951 7.951 0 0 1-.841-.487l-1.91.767-.028.01c-.601.2-1.384.019-1.773-.67l-1.926-3.335-.009-.016c-.32-.605-.196-1.4.38-1.86l.008-.008 1.589-1.24A7.247 7.247 0 0 1 3.8 12c0-.167.008-.334.022-.496l-1.576-1.231a1.465 1.465 0 0 1-.384-1.89l.004-.008 1.921-3.326a1.474 1.474 0 0 1 1.82-.653l.01.004 1.883.757c.266-.176.545-.34.839-.487l.284-2.01zm1.915.74l-.376 2.66-.532.218c-.495.202-.96.479-1.395.808l-.451.34L5.3 6.429 3.83 8.972l2.135 1.667-.094.583c-.043.27-.071.538-.071.778 0 .264.02.534.06.778l.093.583-2.134 1.667 1.469 2.544 2.486-1 .452.345c.442.336.894.602 1.392.805l.532.217.376 2.661h2.936l.376-2.66.532-.218c.495-.202.96-.479 1.395-.808l.451-.34 2.484.998 1.47-2.547-2.09-1.652.067-.556A6.84 6.84 0 0 0 18.2 12c0-.275-.02-.534-.06-.778l-.093-.583 2.134-1.667-1.469-2.544-2.486 1-.452-.345a6.033 6.033 0 0 0-1.392-.805l-.532-.217-.376-2.661h-2.936zM12 9.4A2.61 2.61 0 0 0 9.4 12a2.61 2.61 0 0 0 2.6 2.6 2.61 2.61 0 0 0 2.6-2.6A2.61 2.61 0 0 0 12 9.4zM7.4 12A4.61 4.61 0 0 1 12 7.4a4.61 4.61 0 0 1 4.6 4.6 4.61 4.61 0 0 1-4.6 4.6A4.61 4.61 0 0 1 7.4 12z"
      clipRule="evenodd"
    />
  </SvgIcon>
);
