import { WishListItem } from "./model";
import { ThunkAction } from "redux-thunk";
import axios from "axios";
import { State } from "reducer";
import { Email, RingIdentifier } from "models";
import { ringAndPurity } from "configurator/view/urls";

export const ACTION = {
  LOAD_WISHLIST_REQUEST: "LOAD_WISHLIST_REQUEST",
  LOAD_WISHLIST_SUCCESS: "LOAD_WISHLIST_SUCCESS",
  UPDATE_REQUEST: "UPDATE_REQUEST",
  ADD_ITEM_SUCCESS: "ADD_ITEM_SUCCESS",
  REMOVE_ITEM_SUCCESS: "REMOVE_ITEM_SUCCESS",
  EMPTY_WISHLIST_SUCCESS: "EMPTY_WISHLIST_SUCCESS",
} as const;

export type Action =
  | LoadWishListSuccessAction
  | UpdateRequestAction
  | AddItemSuccessAction
  | RemoveItemSuccessAction
  | EmptyWishListSuccessAction;

type LoadWishListSuccessAction = {
  type: typeof ACTION.LOAD_WISHLIST_SUCCESS;
  payload: {
    items: WishListItem[];
  };
};

type UpdateRequestAction = {
  type: typeof ACTION.UPDATE_REQUEST;
};

type AddItemSuccessAction = {
  type: typeof ACTION.ADD_ITEM_SUCCESS;
  payload: {
    item: WishListItem;
  };
};

type RemoveItemSuccessAction = {
  type: typeof ACTION.REMOVE_ITEM_SUCCESS;
  payload: {
    id: number;
  };
};

export type EmptyWishListSuccessAction = {
  type: typeof ACTION.EMPTY_WISHLIST_SUCCESS;
};

export const loadWishListSuccess = (
  items: WishListItem[]
): LoadWishListSuccessAction => ({
  type: ACTION.LOAD_WISHLIST_SUCCESS,
  payload: { items },
});

export const updateRequest = (): UpdateRequestAction => ({
  type: ACTION.UPDATE_REQUEST,
});

export const addItemSuccess = (item: WishListItem): AddItemSuccessAction => ({
  type: ACTION.ADD_ITEM_SUCCESS,
  payload: { item },
});

export const removeItemSuccess = (id: number): RemoveItemSuccessAction => ({
  type: ACTION.REMOVE_ITEM_SUCCESS,
  payload: { id },
});

export const emptyWishListSuccess = (): EmptyWishListSuccessAction => ({
  type: ACTION.EMPTY_WISHLIST_SUCCESS,
});

const BASE_URL = `/api/v2/wishlist`;

export function loadWishList(): ThunkAction<Promise<void>, State, {}, Action> {
  return async (dispatch) => {
    try {
      const r = await axios.get<WishListItem[]>(BASE_URL);

      dispatch(loadWishListSuccess(r.data));
    } catch (error) {
      dispatch(loadWishListSuccess([]));
    }
  };
}

export function addItem(
  identifier: RingIdentifier
): ThunkAction<Promise<void>, State, {}, Action> {
  return async (dispatch) => {
    try {
      dispatch(updateRequest());

      const jsonResponse = await axios.post<WishListItem>(BASE_URL, identifier);

      dispatch(addItemSuccess(jsonResponse.data));
    } catch (error) {
      if (error.response.status === 401) {
        window.location.href = `${"#/account/login"}?returnUrl=${encodeURIComponent(
          `/Wishlist/Add?${ringAndPurity(identifier)}`
        )}`;
      } else {
        throw error;
      }
    }
  };
}

export function removeItem(
  id: number
): ThunkAction<Promise<void>, State, {}, Action> {
  return async (dispatch) => {
    try {
      dispatch(updateRequest());

      await axios.delete(`${BASE_URL}/${id}`);

      dispatch(removeItemSuccess(id));
    } catch (error) {
      // dispatch(failed(error))
      throw error;
    }
  };
}

export function emptyWishList(): ThunkAction<Promise<void>, State, {}, Action> {
  return async (dispatch) => {
    try {
      dispatch(updateRequest());

      await axios.delete(BASE_URL);

      dispatch(emptyWishListSuccess());
    } catch (error) {
      // dispatch(failed(error))
      throw error;
    }
  };
}

type EmailRequest = Email & {
  url: string; // TODO baseUrl?
};

export function sendEmail(
  email: Email
): ThunkAction<Promise<void>, State, {}, Action> {
  return async () => {
    try {
      const emailRequest: EmailRequest = {
        ...email,
        url: window.location.href,
      };

      await axios.post(`/api/email/wishlist`, emailRequest);
    } catch (error) {
      //TODO
      throw error;
    }
  };
}
