import { Reducer, useReducer, useEffect, useState } from "react";

type State<O> = {
  response: O | null;
  meta: {
    isLoading: boolean;
    isLoaded: boolean;
    isError: boolean;
    error?: any;
  };
};

type ResetAction = {
  type: "RESET";
};

type RequestedAction = {
  type: "REQUESTED";
};

type SuccessAction<O> = {
  type: "SUCCESS";
  payload: O | null;
};

type FailureAction = {
  type: "FAILURE";
  payload: any;
};

type Action<O> =
  | ResetAction
  | RequestedAction
  | SuccessAction<O>
  | FailureAction;

function reducer<O>(state: State<O>, action: Action<O>): State<O> {
  switch (action.type) {
    case "RESET":
      return initial;
    case "REQUESTED":
      return {
        ...state,
        meta: {
          isLoaded: state.meta.isLoaded,
          isLoading: true,
          isError: false,
        },
      };
    case "SUCCESS":
      return {
        response: action.payload,
        meta: {
          isLoaded: true,
          isLoading: false,
          isError: false,
        },
      };
    case "FAILURE":
      return {
        ...state,
        response: null,
        meta: {
          isLoaded: true,
          isLoading: false,
          isError: true,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}

export type AsyncCallResponse<T> = [
  T,
  {
    isLoading: boolean;
    isLoaded: boolean;
    isError: boolean;
    error?: any;
    refresh: () => void;
  }
];

export type AsyncCallConfig = {
  disabled: boolean;
};

const initial = {
  response: null,
  meta: {
    isLoaded: false,
    isLoading: false,
    isError: false,
  },
};

export function useAsyncCall<A>(
  fn?: () => Promise<A>
): AsyncCallResponse<A | null> {
  const [state, dispatch] = useReducer(
    reducer as Reducer<State<A>, Action<A>>,
    initial
  );
  const [counter, setCounter] = useState(0);

  const refresh = () => {
    setCounter(counter + 1);
  };

  useEffect(() => {
    let isMounted = true;

    if (!fn) {
      dispatch({ type: "RESET" });
    } else {
      dispatch({ type: "REQUESTED" });

      fn()
        .then((response) => {
          if (isMounted) {
            dispatch({ type: "SUCCESS", payload: response });
          }
        })
        .catch((error) => {
          if (isMounted) {
            dispatch({ type: "FAILURE", payload: error });
          }
        });
    }
    return () => {
      isMounted = false;
    };
  }, [fn, counter]);

  return [
    state.response,
    {
      ...state.meta,
      refresh,
    },
  ];
}
