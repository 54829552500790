import {
  FilterInfo,
  FilterSelection,
  SearchResults,
  Page,
  UrlFilterSelection,
  PriceRange,
  FilterType,
  Sorting,
} from "./model";
import { ThunkAction } from "redux-thunk";
import { State } from "reducer";
import Axios from "axios";

export const ACTION = {
  LOAD_INITIAL_REQUEST: "LOAD_INITIAL_REQUEST",
  LOAD_INITIAL_SUCCESS: "LOAD_INITIAL_SUCCESS",
  UPDATE_FILTER_REQUEST: "UPDATE_FILTER_REQUEST",
  UPDATE_FILTER_SUCCESS: "UPDATE_FILTER_SUCCESS",
  LOAD_PAGE_REQUEST: "LOAD_PAGE_REQUEST",
  LOAD_PAGE_SUCCESS: "LOAD_PAGE_SUCCESS",
} as const;

export type Action =
  | LoadInitialRequestAction
  | LoadInitialSuccessAction
  | UpdateFilterRequestAction
  | UpdateFilterSuccessAction
  | LoadPageRequestAction
  | LoadPageSuccessAction;

type LoadInitialRequestAction = {
  type: typeof ACTION.LOAD_INITIAL_REQUEST;
};

type LoadInitialSuccessAction = {
  type: typeof ACTION.LOAD_INITIAL_SUCCESS;
  payload: {
    filter: FilterInfo;
    results: SearchResults;
  };
};

type UpdateFilterRequestAction = {
  type: typeof ACTION.UPDATE_FILTER_REQUEST;
  payload: {
    filterId: number;
    selection: FilterSelection;
  };
};

type UpdateFilterSuccessAction = {
  type: typeof ACTION.UPDATE_FILTER_SUCCESS;
  payload: {
    filterId: number;
    selection: FilterSelection;
    results: SearchResults;
  };
};

type LoadPageRequestAction = {
  type: typeof ACTION.LOAD_PAGE_REQUEST;
};

type LoadPageSuccessAction = {
  type: typeof ACTION.LOAD_PAGE_SUCCESS;
  payload: {
    filterId: number;
    page: Page;
    totalLoaded: number;
  };
};

let filterId = 1;

export const loadInitialRequest = (): LoadInitialRequestAction => ({
  type: ACTION.LOAD_INITIAL_REQUEST,
});

export const loadInitialSucces = (
  data: LoadInitialSuccessAction["payload"]
): LoadInitialSuccessAction => ({
  type: ACTION.LOAD_INITIAL_SUCCESS,
  payload: data,
});

export const updateFilterRequest = (
  selection: FilterSelection
): UpdateFilterRequestAction => ({
  type: ACTION.UPDATE_FILTER_REQUEST,
  payload: {
    filterId: filterId++,
    selection,
  },
});

export const updateFilterSuccess = (
  data: UpdateFilterSuccessAction["payload"]
): UpdateFilterSuccessAction => ({
  type: ACTION.UPDATE_FILTER_SUCCESS,
  payload: data,
});

export const loadPageRequest = (): LoadPageRequestAction => ({
  type: ACTION.LOAD_PAGE_REQUEST,
});

export const loadPageSuccess = (
  data: LoadPageSuccessAction["payload"]
): LoadPageSuccessAction => ({
  type: ACTION.LOAD_PAGE_SUCCESS,
  payload: data,
});

export function initialLoad(
  urlFilterSelection: UrlFilterSelection | null
): ThunkAction<Promise<void>, State, {}, Action> {
  return async (dispatch) => {
    try {
      dispatch(loadInitialRequest());

      const response = await update<LoadInitialSuccessAction["payload"]>(
        "POST",
        "initial",
        urlFilterSelection
      );

      dispatch(loadInitialSucces(response));
    } catch (error) {
      // dispatch(failed(error))
      throw error;
    }
  };
}

export enum FilterUpdateType {
  Sorting,
  Price,
  Category,
  Clear,
}

export type FilterUpdate =
  | { type: FilterUpdateType.Clear }
  | { type: FilterUpdateType.Sorting; sorting: Sorting }
  | { type: FilterUpdateType.Price; priceRange: PriceRange }
  | { type: FilterUpdateType.Category; filterType: FilterType; ids: number[] };

export function updateFilter(
  upd: FilterUpdate,
  newMobileView: boolean
): ThunkAction<Promise<void>, State, {}, Action> {
  return async (dispatch, getState) => {
    try {
      const selection = merge(getState().search.filter!, upd, newMobileView);

      const request = updateFilterRequest(selection);

      dispatch(request);

      const response = await update<UpdateFilterSuccessAction["payload"]>(
        "POST",
        "update-filter",
        { filterId: request.payload.filterId, selection }
      );

      dispatch(updateFilterSuccess(response));
    } catch (error) {
      // dispatch(failed(error))
      throw error;
    }
  };
}

function merge(
  filter: FilterInfo,
  upd: FilterUpdate,
  newMobileView: Boolean
): FilterSelection {
  const selection = filter.selection;
  switch (upd.type) {
    case FilterUpdateType.Sorting:
      return { ...selection, sorting: upd.sorting };

    case FilterUpdateType.Price:
      return { ...selection, priceRange: upd.priceRange };

    case FilterUpdateType.Category:
      return {
        ...selection,
        categories: selection.categories.map((c) => {
          if (c.filterType === upd.filterType) {
            return newMobileView
              ? {
                  filterType: upd.filterType,

                  // Convert empty selection back to selection of all options. Possibility to simplify if we change backend
                  ids:
                    upd.ids.length === 0
                      ? filter.categories
                          .find((c) => c.filterType === upd.filterType)!
                          .items.map((item) => item.id)
                      : upd.ids,
                }
              : { filterType: upd.filterType, ids: upd.ids };
          } else {
            return c;
          }
        }),
      };

    case FilterUpdateType.Clear:
      return {
        priceRange: { ...filter.priceRange },
        sorting: { ...filter.selection.sorting },
        categories: filter.categories.map((c) => ({
          filterType: c.filterType,
          ids: c.items.map((i) => i.id),
        })),
      };
  }
}

export function loadPage(
  pageNumber: number
): ThunkAction<Promise<void>, State, {}, Action> {
  return async (dispatch, getState) => {
    const { search: state } = getState();

    try {
      dispatch(loadPageRequest());

      const response = await update<LoadPageSuccessAction["payload"]>(
        "POST",
        `page/${pageNumber}`,
        { filterId: state.filterId, selection: state.filter!.selection }
      );

      dispatch(loadPageSuccess(response));
    } catch (error) {
      // dispatch(failed(error))
      throw error;
    }
  };
}

async function update<TResult>(
  method: "GET" | "POST",
  url: string,
  body: any = null
): Promise<TResult> {
  const r = await Axios.request<TResult>({
    url: `/api/search/${url}`,
    method,
    data: body,
  });
  return r.data;
}
