import { RingView } from "./model";
import { ACTION, Action, ConfiguratorUpdateType } from "./actions";

export type State = {
  readonly isLoading: boolean;
  readonly isUpdating: boolean;
  readonly ringView: RingView | null;
};

const defaultState = {
  isLoading: true,
  isUpdating: false,
  ringView: null,
};

export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case ACTION.LOAD_RING_REQUEST:
      // We only want to show the loading spinner if there is not currently one shown
      return action.payload.updateType === ConfiguratorUpdateType.Initial
        ? { ...state, ringView: null, isLoading: true }
        : { ...state, isUpdating: true };
    case ACTION.LOAD_RING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        ringView: action.payload.ringView,
      };
    default:
      return state;
  }
};
