import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export default (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M16 10.0027C15.5988 10.0027 15.1375 10.1496 14.6 10.5527C14.1582 10.8841 13.5314 10.7945 13.2 10.3527C12.8686 9.91088 12.9582 9.28408 13.4 8.9527C14.1958 8.35582 15.0678 8.0027 16 8.0027C16.9322 8.0027 17.8042 8.35582 18.6 8.9527C19.0418 9.28408 19.1314 9.91088 18.8 10.3527C18.4686 10.7945 17.8418 10.8841 17.4 10.5527C16.8625 10.1496 16.4012 10.0027 16 10.0027Z" />
    <path d="M14.6 14.8027C15.1375 14.3996 15.5988 14.2527 16 14.2527C16.4012 14.2527 16.8625 14.3996 17.4 14.8027C17.8418 15.1341 18.4686 15.0445 18.8 14.6027C19.1314 14.1609 19.0418 13.5341 18.6 13.2027C17.8042 12.6058 16.9322 12.2527 16 12.2527C15.0678 12.2527 14.1958 12.6058 13.4 13.2027C12.9582 13.5341 12.8686 14.1609 13.2 14.6027C13.5314 15.0445 14.1582 15.1341 14.6 14.8027Z" />
    <path d="M8 10.0027C7.59883 10.0027 7.1375 10.1496 6.6 10.5527C6.15817 10.8841 5.53137 10.7945 5.2 10.3527C4.86863 9.91088 4.95817 9.28408 5.4 8.9527C6.19583 8.35582 7.06783 8.0027 8 8.0027C8.93217 8.0027 9.80416 8.35582 10.6 8.9527C11.0418 9.28408 11.1314 9.91088 10.8 10.3527C10.4686 10.7945 9.84182 10.8841 9.4 10.5527C8.8625 10.1496 8.40116 10.0027 8 10.0027Z" />
    <path d="M6.6 14.8027C7.1375 14.3996 7.59883 14.2527 8 14.2527C8.40116 14.2527 8.8625 14.3996 9.4 14.8027C9.84182 15.1341 10.4686 15.0445 10.8 14.6027C11.1314 14.1609 11.0418 13.5341 10.6 13.2027C9.80416 12.6058 8.93217 12.2527 8 12.2527C7.06783 12.2527 6.19583 12.6058 5.4 13.2027C4.95817 13.5341 4.86863 14.1609 5.2 14.6027C5.53137 15.0445 6.15817 15.1341 6.6 14.8027Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9839 5.49022C7.65225 2.59739 3.14288 4.568 1.38251 5.94996C1.14102 6.13953 1 6.42953 1 6.73653V19.0027C1 19.3854 1.21847 19.7346 1.56267 19.902C1.90688 20.0694 2.31643 20.0256 2.61749 19.7893C6.09969 17.0556 9.89561 18.622 11.3825 19.7893C11.7302 20.0623 12.216 20.0745 12.577 19.8194C16.5434 17.017 20.1037 18.6621 21.338 19.7522C21.6328 20.0125 22.0527 20.0759 22.4111 19.9143C22.7696 19.7526 23 19.3959 23 19.0027V6.73653C23 6.41176 22.8423 6.10722 22.577 5.91982C18.1783 2.81195 14.0467 4.09306 11.9839 5.49022ZM3 7.24907V17.1815C6.58279 15.3753 10.136 16.6002 12.0151 17.7927C15.5591 15.5861 18.8741 16.1384 21 17.172V7.27002C17.2055 4.86758 13.8523 6.43484 12.662 7.48607C12.3003 7.8055 11.7621 7.82108 11.3825 7.52311C8.16791 4.99954 4.68595 6.14049 3 7.24907Z"
    />
  </SvgIcon>
);
