import { RingIdentifier } from "models";
import { serverData } from "serverData";
import axios from "axios";

interface OrderRequest {
  rings: OrderRequestItem[];
}

interface OrderRequestItem {
  reference: string;
  purities: MaterialPurityPair[];
  ringSize: string | null;
}

// Legacy version, from when multiple purities where still possible
interface MaterialPurityPair {
  key: number;
  value: number;
}

export async function createOrder(
  onError: () => void,
  ...rings: RingIdentifier[]
) {
  try {
    if (rings.length > serverData.maxOrderRings) {
      alert(
        "A maximum of " +
          serverData.maxOrderRings +
          " can be ordered at the same time."
      );
      return;
    }

    const orderRequest: OrderRequest = {
      rings: rings.map((r) => ({
        reference: r.reference,
        purities: purityToPair(r.purity),
        ringSize: r.selectedRingSize?.localized || null,
      })),
    };

    const jsonResponse = await axios.post<{ id: number }>(
      "/api/orders",
      orderRequest
    );

    window.location.href = "/Order/Index/" + jsonResponse.data.id;
  } catch (error) {
    onError();
    throw error;
  }
}

function purityToPair(purity?: number | null): MaterialPurityPair[] {
  if (!purity) return [];

  return [
    {
      key: 0,
      value: purity,
    },
  ];
}
