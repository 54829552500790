import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

//@ts-ignore
import smoothscroll from "smoothscroll-polyfill";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

smoothscroll.polyfill();

ReactDOM.render(<App />, document.getElementById("root"));

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace NodeJS {
    interface Global {
      __REDUX_DEVTOOLS_EXTENSION__: any;
    }
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
