import React, { Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import {
  HashRouter,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { CssBaseline, ThemeProvider } from "@material-ui/core";

import "./interceptors";

import { SearchPage } from "search/view/SearchPage";
import { ConfiguratorPage } from "configurator/view/ConfiguratorPage";
import { WishListPage } from "wishlist/view/WishListPage";
import AccountRoutes from "account/view/Routes";

import store from "./store";
import { serverData } from "./serverData";
import { loadWishList } from "wishlist/model/actions";

import "./header-interop";
import { updateMakeAppointmentUrls } from "header-interop";
import skin from "skin";

i18n
  .use(XHR)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: serverData.language.code,
    interpolation: {
      escapeValue: false,
    },
    backend: { loadPath: "/translations/{{lng}}" },
  });

const Router: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(loadWishList());
  }, [dispatch]);

  useEffect(() => {
    updateMakeAppointmentUrls(history.location.search);
    history.listen((listener) => updateMakeAppointmentUrls(listener.search));
  }, [history]);

  return (
    <Switch>
      <Route name="ring" path="/ring">
        <ThemeProvider theme={skin.theme}>
          <CssBaseline />

          <ConfiguratorPage />
        </ThemeProvider>
      </Route>
      <Route name="search" path="/search">
        <ThemeProvider theme={skin.theme}>
          <CssBaseline />

          <SearchPage />
        </ThemeProvider>
      </Route>
      <Route name="wishlist" path="/wishlist">
        <ThemeProvider theme={skin.theme}>
          <CssBaseline />

          <WishListPage />
        </ThemeProvider>
      </Route>
      <Route name="account" path="/account">
        <ThemeProvider theme={skin.theme}>
          <CssBaseline />

          <Route component={AccountRoutes} />
        </ThemeProvider>
      </Route>
      <Redirect exact from="/" to="/search" />
    </Switch>
  );
};

const App: React.FC = () => (
  <Provider store={store}>
    <Suspense fallback="">
      <HashRouter>
        <Router />
      </HashRouter>
    </Suspense>
  </Provider>
);

export default App;
