import { RingView } from "configurator/model/model";

import queryString from "query-string";
import { RingIdentifier } from "models";

const PURITY_PREFIX = "purity";

export const ringUrl = (
  path: string,
  ringView: RingView,
  includeConfigurationType: Boolean = true
) => {
  const rs = ringView.selectedRingSize
    ? `&ringSize=${ringView.selectedRingSize?.ringSize}`
    : "";
  const ct =
    includeConfigurationType && ringView.selectedConfigurationType
      ? `&selectedConfigurationType=${ringView.selectedConfigurationType}`
      : "";

  return `${path}?${ringAndPurity(ringView)}${rs}${ct}`;
};

export const ringLink = (request: RingIdentifier) => ({
  pathname: "/ring",
  search: ringAndPurity(request),
});

export const ringAndPurity = ({ reference, purity }: RingIdentifier) =>
  `reference=${reference}` + (purity ? `&${PURITY_PREFIX}=${purity}` : "");

export const parseRingFromUrl = (search: string) => {
  const params = queryString.parse(search);

  const reference = params.reference as string;

  const selectedConfigurationType = params.selectedConfigurationType as
    | string
    | null;

  const ringSize = params.ringSize as string | null;

  const purityKey = Object.keys(params).find((p) =>
    p.startsWith(PURITY_PREFIX)
  );

  return {
    reference,
    ringSize: ringSize ? +ringSize : null,
    purity: purityKey ? +(params[purityKey] as string) : null,
    selectedConfigurationType,
  };
};
