import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export default (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 20c-4.667 0-8.333-2.667-11-8 2.667-5.333 6.333-8 11-8s8.333 2.667 11 8c-2.667 5.333-6.333 8-11 8zm0-3a5 5 0 100-10 5 5 0 000 10zm0-2a3 3 0 110-6 3 3 0 010 6z"
    />
  </SvgIcon>
);
