import React from "react";
import { Paper, List, ListItem, ListItemText } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { RingView } from "configurator/model/model";

type Props = {
  ringView: RingView;
};

export const ProductDetails: React.FC<Props> = ({ ringView }) => {
  const { t } = useTranslation();

  return (
    <Paper variant="outlined">
      <List disablePadding>
        <ListItem divider>
          <ListItemText
            primary={t("Ring_Reference")}
            secondary={ringView.reference}
          />
        </ListItem>

        {ringView.properties.map((property, idx) => (
          <ListItem
            key={property.key}
            divider={ringView.properties.length !== idx + 1}
          >
            <ListItemText primary={property.key} secondary={property.value} />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};
