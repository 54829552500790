import React from "react";

export default () => (
  <svg width="24" height="24" style={{ overflow: "unset" }}>
    <rect
      x="0.5"
      y="0.5"
      width="23"
      height="23"
      rx="11.5"
      stroke="currentColor"
      fill="none"
    />
  </svg>
);
